import {INTERACTION_MODE, SimulatorContext} from "../context/SimulatorContext";
import * as React from "react";
import useSWR from "swr";
import ErrorPage from "../pages/ErrorPage";
import LoadingPage from "../pages/LoadingPage";
import LoginPage from "../pages/LoginPage";
import Layout from "../components/Layout";
import List from "@mui/joy/List";
import navigations from "../data/navigations";
import {NavListItem} from "../components/navbar/NavListItem";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import RainLogo from "../rain-logo.png";
import RainStationSVG from "../Rain-Station-inverted.svg";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import {ColorSchemeToggle} from "../components/navbar/ColorSchemeToggle";
import UserProfileMenu from "../components/navbar/UserProfileMenu";
import Grid from "@mui/material/Unstable_Grid2";
import Sheet from "@mui/joy/Sheet";
import WorldContainer from "../components/WorldContainer";
import {MapInteractionModeButton} from "../components/navbar/MapInteractionModeButton";
import SimulatorPlayback from "../components/SimulatorPlayback";
import {useMemo} from "react";

export function PageContent() {
  const [store, setStore] = SimulatorContext.useStore(
    store => store
  );
  // const [interactionMode, setInteractionMode] = React.useState(INTERACTION_MODE.VIEW);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const {data: apiToken, isLoading, error} = useSWR("apiToken", () => {
    return window.localStorage.getItem("RAIN-ISIM-TOKEN")
  })

  if (error) {
    return <ErrorPage/>
  }

  if (isLoading) {
    return <LoadingPage/>
  }

  if (!apiToken) {
    return <LoginPage/>
  }

  const SideBarItems = (
    <List size="sm" sx={{'--List-item-radius': '8px', '--List-gap': '4px'}}>
      {navigations.map(navigation => {
        return (
          <NavListItem {...navigation} key={navigation.title}/>
        )
      })}
    </List>
  )

  let mapDisplayProperty: "block" | "none" = "none"
  if (!store.activeMenu || store.activeMenu.isMapVisible == null || store.activeMenu.isMapVisible) {
    mapDisplayProperty = "block"
  }

  return (
    <>
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          {SideBarItems}
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden',
          }),
        }}
      >
        <Layout.Header>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1.5,
            }}
          >
            <IconButton
              variant="outlined"
              size="sm"
              onClick={() => setDrawerOpen(true)}
              sx={{display: {sm: 'none'}}}
            >
              <MenuIcon/>
            </IconButton>
            <img alt={"Logo"} src={RainLogo} style={{height: '2em'}}></img>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: 1.5}}>
            {MapInteractionModeButton(
              INTERACTION_MODE.ADD_STATION,
              setStore,
              store.interactionMode,
              <img src={RainStationSVG} alt="logo" style={{width: '1.8em'}}/>
            )}
            {MapInteractionModeButton(
              INTERACTION_MODE.DRAW_REGION,
              setStore,
              store.interactionMode,
              <ShapeLineIcon/>
            )}
            {MapInteractionModeButton(
              INTERACTION_MODE.START_FIRE,
              setStore,
              store.interactionMode,
              <LocalFireDepartmentIcon/>
            )}
            <ColorSchemeToggle/>
            <UserProfileMenu/>
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          {SideBarItems}
        </Layout.SideNav>
        <Layout.Main>
          <Box sx={{flexGrow: 1}}>
            <Grid container alignItems={'stretch'} spacing={1}>
              {!!store.activeMenu && (
                <>
                  {
                    store.activeMenu.isMapVisible ?
                      <Grid xs={12} md={6} lg={5} xl={3}>
                        {store.activeMenu.content}
                      </Grid>
                      :
                      <Grid xs>
                        {store.activeMenu.content}
                      </Grid>
                  }
                </>
              )}
              <Grid xs sx={{
                display: mapDisplayProperty
              }}>
                <Sheet
                  variant="outlined"
                  sx={{
                    minHeight: 500,
                    height:
                      store.interactionMode === INTERACTION_MODE.SIMULATION
                        ? '75vh'
                        : '90vh',
                  }}
                >
                  <WorldContainer/>
                </Sheet>
                {store.interactionMode === INTERACTION_MODE.SIMULATION && (
                  <SimulatorPlayback />
                )}
              </Grid>
            </Grid>
          </Box>
        </Layout.Main>
      </Layout.Root>
    </>
  );
}
