import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AircraftAttributes,
  OptionType,
  SimulatorContext,
  Station,
} from '../../context/SimulatorContext';
import {useEffect, useState} from 'react';
import LoadConfirmationModal from '../modals/LoadConfirmationModal';
import {v4 as uuidv4} from 'uuid';
import {Card, Container, LinearProgress} from "@mui/joy";
import {FlightOutlined} from "@mui/icons-material";
import Swal from 'sweetalert2';

const FILE_VERSION = '1.0.1';

export type AircraftType = {
  aircraftName: string;
  aircraftAttributes: OptionType;
};

type InstallationDataType = {
  stations: {
    [key: string]: Station;
  };
  aircraftTypes: AircraftType[];
};

type InstallationFileType = {
  version: string;
  data: InstallationDataType;
};

function StationInfoBlock({
  station,
  aircraftName,
  aircraftAttributes,
  handleDelete,
}: {
  station: Station;
  aircraftName: string;
  aircraftAttributes: AircraftAttributes;
  handleDelete: (stationId: string) => any;
}) {
  const [worldMapViewState, setWorldMapViewState] = SimulatorContext.useStore(store => store.worldMapViewState)
  const [selectedStationId, setSelectedStationId] = SimulatorContext.useStore(store => store.selectedStationId)
  const aircrafts = [
    {
      name: aircraftName,
      cruisingSpeed: aircraftAttributes.cruisingSpeed,
      cargoPayload: aircraftAttributes.cargoPayload,
      timeToTakeoff: aircraftAttributes.timeToTakeoff,
      endurance: aircraftAttributes.endurance,
      windTolerance: aircraftAttributes.windTolerance,
    },
  ];
  const coordinates =
    !Number.isNaN(station.location.lat) && !Number.isNaN(station.location.lng)
      ? `${station.location.lat.toFixed(5)}, ${station.location.lng.toFixed(5)}`
      : '';

  async function zoomToStation() {
    setSelectedStationId({
      selectedStationId: selectedStationId === station.id ? undefined : station.id
    })
    setWorldMapViewState({
      worldMapViewState: {
        ...worldMapViewState,
        latitude: station.location.lat,
        longitude: station.location.lng
      }
    })
  }

  return (
    <Card
      id={station.id}
      color='primary'
      variant={station.id === selectedStationId ? 'soft' : 'plain'}
      sx={{border: '1px solid white', cursor: 'pointer'}}
      onClick={zoomToStation}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <>
          <Typography level="body1" textColor="text.primary">
            {'Station '}
            <Typography level="body4" textColor="text.primary">
              {coordinates}
            </Typography>
          </Typography>
        </>

        <IconButton
          size="sm"
          variant="plain"
          color="danger"
          sx={{'--IconButton-size': '24px'}}
          onClick={(event) => {
            event.stopPropagation()
            handleDelete(station.id)
          }}
        >
          <DeleteIcon fontSize="small" color="error"/>
        </IconButton>
      </Box>

      <Typography level="body4" textColor="text.primary">
        {`ID: ${station.id}`}
      </Typography>

      <Box>
        <Box sx={{mt: 1, display: 'flex', flexDirection: 'column', gap: 1}}>
          {aircrafts.map(aircraft => {
            return (
              <Box key={aircraft.name}>
                <Typography level="body2">
                  <div style={{
                    display: "flex",
                    gap: "5px",
                    marginBottom: "5px",
                    alignItems: "center"
                  }}>
                    <FlightOutlined/><strong>{aircraft.name}</strong>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                    Aircraft Count
                    </div>
                    <div>
                      {station.aircraftCount}
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      Cruising Speed
                    </div>
                    <div>
                      {aircraft.cruisingSpeed} mph
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      Cargo Payload
                    </div>
                    <div>
                      {aircraft.cargoPayload} lbs
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      Time to Takeoff
                    </div>
                    <div>
                      {aircraft.timeToTakeoff} seconds
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      Endurance (Flight Time)
                    </div>
                    <div>
                      {aircraft.timeToTakeoff} minutes
                    </div>
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      Wind Tolerance
                    </div>
                    <div>
                      {aircraft.windTolerance} mph
                    </div>
                  </div>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Card>
  );
}

export default function InstallationSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  const [stations, setStations] = SimulatorContext.useStore(
    store => store.stations
  );
  const [selectedAircraftName, setSelectedAircraftName] = SimulatorContext.useStore(
    store => store.selectedAircraftName
  );
  const [aircraftOptions, setAircraftOptions] = SimulatorContext.useStore(
    store => store.aircraftOptions
  );
  const [showLoadModal, setShowLoadModal] = useState(false);
  const [dataLoad, setDataLoad] = useState<InstallationDataType>({
    stations: {},
    aircraftTypes: [],
  });

  // Scroll to the selected station
  useEffect(() => {
    const stationElement = document.getElementById(store.selectedStationId);
    if (stationElement) {
      stationElement.scrollIntoView({behavior: 'smooth'});
    }
  }, [store.selectedStationId]);

  function handleSave() {
    if (Object.values(stations).length === 0) return;
    try {
      const payload: InstallationFileType = {
        version: FILE_VERSION,
        data: {
          stations: stations,
          aircraftTypes: [
            {
              aircraftName: selectedAircraftName,
              aircraftAttributes: aircraftOptions[selectedAircraftName],
            },
          ],
        },
      };
      const json = JSON.stringify(payload, null, 2);
      const blob = new Blob([json], {type: 'application/json'});
      const url = URL.createObjectURL(blob);
      const dateString = new Date()
        .toISOString()
        .replace(/T/, '-')
        .replace(/\..+/, '')
        .replace(':', '-')
        .replace(':', '-');

      const link = document.createElement('a');
      link.href = url;
      link.download = `Installation-${dateString}.json`;
      link.click();

      // Release the object URL after the download has started
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download JSON:', error);
    }
  }

  function handleLoad(event: any) {
    if (!event) return;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      try {
        const parsed: InstallationFileType = JSON.parse(event.target.result);
        if (parsed.version !== FILE_VERSION) {
          throw new Error('Invalid file version');
        }
        const data = parsed.data;
        if (Object.values(stations).length === 0) {
          setStations({stations: data.stations});
          const newAircraftName = data.aircraftTypes[0].aircraftName;
          setSelectedAircraftName({selectedAircraftName: newAircraftName});
          setAircraftOptions({
            aircraftOptions: {
              ...aircraftOptions,
              [newAircraftName]: {
                ...aircraftOptions[newAircraftName],
                ...data.aircraftTypes[0].aircraftAttributes
              }
            }
          });
          return;
        }
        setDataLoad(data);
        setShowLoadModal(true);
      } catch (error) {
        console.error('Failed to parse JSON:', error);
        alert('Failed to parse JSON');
      }
    };

    reader.readAsText(file);
    event.target.value = null;
  }

  function handleLoadConflict({isReplace}: { isReplace: boolean }) {
    if (isReplace) {
      setStations({stations: dataLoad.stations});
      setSelectedAircraftName({selectedAircraftName: dataLoad.aircraftTypes[0].aircraftName});
      setAircraftOptions({
        ...aircraftOptions,
        [selectedAircraftName]: {
          ...aircraftOptions[selectedAircraftName],
          ...dataLoad.aircraftTypes[0].aircraftAttributes,
        }
      });
      setShowLoadModal(false);
      return;
    }
    let newStations = {...stations};
    for (const [key, value] of Object.entries(dataLoad.stations)) {
      if (!newStations[key]) {
        newStations[key] = value;
        continue;
      }
      const newId = uuidv4();
      newStations[newId] = {...value, id: newId};
    }
    setStations({stations: newStations});
    setShowLoadModal(false);
  }

  function handleDelete(stationId: string) {
    let newTempStations = {...stations};
    delete newTempStations[stationId];
    setStations({
      stations: newTempStations,
    });
  }

  const onClose = () =>
    setStore({
      ...store,
      activeMenu: null,
    });

  return (
    <Container
      sx={{
        pl: '8px !important',
        maxHeight: '85vh',
        overflowY: 'auto',
      }}
    >
      <LoadConfirmationModal
        isOpen={showLoadModal}
        onClose={() => setShowLoadModal(false)}
        handleLoadConflict={handleLoadConflict}
      />
      <Box
        sx={{
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Review installation
        </Typography>
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>

      <Box
        sx={{pb: 2, display: 'flex', rowGap: 1, columnGap: 2, flexWrap: 'wrap'}}
      >
        <Button
          size="sm"
          variant="solid"
          onClick={handleSave}
          component="label"
          disabled={Object.values(stations).length === 0}
        >
          Download
        </Button>
        <Button size="sm" variant="solid" component="label">
          Upload
          <input
            type="file"
            accept="application/json"
            onChange={handleLoad}
            hidden
          />
        </Button>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center', paddingBottom: 1}}>
        <Typography level="body2" textColor="text.primary">
          Total Installations: {Object.values(stations).length}
        </Typography>
        {Object.values(stations).length > 0 && <Button
          size="sm"
          variant="outlined"
          color="danger"
          sx={{fontSize: 'xs', marginLeft: 'auto'}}
          onClick={() => {
            Swal.fire({
              title: 'Remove all installations',
              text: 'Are you sure you want to remove all installations?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#dd3333',
              confirmButtonText: 'Confirm',
            }).then(result => {
              if (result.isConfirmed) {
                setStations({stations: {}});
              }
            });
          }}
        >
          Remove All
        </Button>}
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        {Object.values(stations).map(station => (
          <StationInfoBlock
            key={station.id}
            station={station}
            aircraftName={selectedAircraftName}
            aircraftAttributes={aircraftOptions[selectedAircraftName]}
            handleDelete={handleDelete}
          />
        ))}
      </Box>
    </Container>
  );
}
