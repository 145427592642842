import * as turf from '@turf/turf';
import {useState} from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Box, Chip, Grid, IconButton} from '@mui/joy';
import {GeneratedRegionType} from '../../data/InstallationTypes';
import _ from 'lodash';
import moment from 'moment';
import {KeyboardArrowDown} from '@mui/icons-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  INTERACTION_MODE,
  SimulationParameters,
  SimulatorContext,
  Station,
} from '../../context/SimulatorContext';
import {v4 as uuidv4} from 'uuid';

export const GenerationDetailModal = ({
  generation,
  isOpen,
  onClose,
  mutate,
  setActiveGeneration,
}: {
  generation: GeneratedRegionType;
  isOpen: boolean;
  onClose: () => void;
  mutate: () => void;
  setActiveGeneration: (generationId: string) => void;
}) => {
  const [showAircraft, setShowAircraft] = useState(false);
  const [showDetect, setShowDetect] = useState(false);
  const [showControl, setShowControl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = window.localStorage.getItem('RAIN-ISIM-TOKEN');
  const [store, setStore] = SimulatorContext.useStore(store => store);

  async function doLoadDataFromGeneration(generationId: string) {
    const shouldContinue = await Swal.fire({
      icon: 'info',
      title: 'Load Generated Installations',
      text: `This will update your current drawn region, installations, aircraft, and containment parameters to match the environment from when you trigger the generate installations.`,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      animation: false,
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;

    // Get load region
    setIsLoading(true);
    const region = await axios.get<GeneratedRegionType>(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}/load-region`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    const installations = region.data.result?.installations || [];

    // Create a copy of stations list
    let newStations: {
      [key: string]: Station;
    } = {};

    // Add newly generated installations to stations list
    for (const installation of installations) {
      // Assign new ID for the installation, and add to stations list
      const newId = uuidv4();
      newStations[newId] = {
        id: newId,
        location: {
          lat: installation.location.lat,
          lng: installation.location.lng,
        },
        aircraftCount: installation.aircraftCount,
      };
    }

    const selectedAircraftName =
      generation.input.aircraft.name || store.selectedAircraftName;
    const newValue: Partial<SimulationParameters> = {
      protectedRegion: generation.input.region,
      selectedAircraftName,
    };

    if (
      generation.input.controlLineAttributes &&
      Object.keys(generation.input.controlLineAttributes).length !== 0
    ) {
      newValue['controlLineAttributes'] =
        generation.input.controlLineAttributes;
    }

    if (
      generation.input.detectionAttributes &&
      Object.keys(generation.input.detectionAttributes).length !== 0
    ) {
      newValue['activeDetectionSystems'] = [
        generation.input.detectionAttributes,
      ];
    }

    if (
      generation.input.aircraft.cruisingSpeed &&
      generation.input.aircraft.cargoPayload &&
      generation.input.aircraft.endurance &&
      generation.input.aircraft.windTolerance
    ) {
      newValue['aircraftOptions'] = {
        ...store.aircraftOptions,
        [selectedAircraftName]: {
          ...store.aircraftOptions[selectedAircraftName],
          ...generation.input.aircraft,
        },
      };
    }

    newValue.stations = newStations;
    newValue.interactionMode = INTERACTION_MODE.VIEW;

    // Center map to the region
    const regionArr = generation.input.region.map(r => [r.lng, r.lat]);
    const polygon = turf.polygon([[...regionArr, regionArr[0]]]);
    const center = turf.center(polygon);
    newValue.worldMapViewState = {
      ...store.worldMapViewState,
      latitude: center.geometry.coordinates[1],
      longitude: center.geometry.coordinates[0],
    };

    setStore(newValue);
    setActiveGeneration(generation.id);
    setIsLoading(false);
    onClose();
  }

  async function updateStatus(
    generationId: string,
    status: GeneratedRegionType['status']
  ) {
    const shouldContinue = await Swal.fire({
      icon: 'info',
      title: 'Update Generation Status',
      text: `This will update the status of the generation to ${status}.`,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;

    setIsLoading(true);
    await axios.patch(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}`,
      {
        status: status,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    setIsLoading(false);
    mutate();
  }

  async function handleDelete(generationId: string) {
    const shouldContinue = await Swal.fire({
      title: 'Remove Generation',
      text: `Are you sure you want to remove ${generation.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dd3333',
      confirmButtonText: 'Confirm',
    }).then(async result => {
      return result.isConfirmed;
    });

    if (!shouldContinue) return;

    setIsLoading(true);
    await axios.delete(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/${generationId}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    setIsLoading(false);
    mutate();
    onClose();
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(0px)',
        zIndex: 1000,
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: 500,
          borderRadius: 'md',
          p: 3,
          overflowY: 'scroll',
          maxHeight: '80%',
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{m: 1}} />
        <Typography
          id="modal-title"
          component="h3"
          level="h3"
          textAlign="center"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Generation Details
        </Typography>
        <Typography level="body2">
          <Grid container alignItems={'center'}>
            <Grid xs={12}>
              <Typography level="body1" fontWeight={700}>
                General
              </Typography>
            </Grid>
            <Grid xs={6}>Name</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography level="body2">{generation.name}</Typography>
            </Grid>
            <Grid xs={6}>Status</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography
                level="body2"
                color={
                  generation.status === 'DONE'
                    ? 'success'
                    : generation.status === 'PAUSED'
                    ? 'neutral'
                    : 'warning'
                }
                fontWeight={700}
              >
                {generation.status}
              </Typography>
            </Grid>
            <Grid xs={6}>Strategy</Grid>
            <Grid xs={6} textAlign={'right'}>
              <Typography level="body2">
                <Chip size="sm" color={'info'}>
                  {generation.strategy}
                </Chip>
              </Typography>
            </Grid>
            <Grid xs={6}>Refill Attempts</Grid>
            <Grid xs={6} textAlign={'right'}>
              {generation.input.refillAttempt || 0}
            </Grid>
          </Grid>
        </Typography>
        {generation.result && (
          <Typography level="body2">
            <Typography level="body1" fontWeight={700}>
              Result
            </Typography>
            <Grid container alignItems={'center'}>
              <Grid xs={6}>Installations Count</Grid>
              <Grid xs={6} textAlign={'right'}>
                {generation.result.installationsCount} Station
              </Grid>
              <Grid xs={6}>Progress</Grid>
              <Grid
                xs={6}
                textAlign={'right'}
              >{`${generation.result.processed} of ${generation.result.total} tiles`}</Grid>
              {generation.result?.startedAt && (
                <>
                  <Grid xs={6}>Started At</Grid>
                  <Grid xs={6} textAlign={'right'}>
                    {moment(generation.result.startedAt).format('LLL')}
                  </Grid>
                </>
              )}
              {generation.result?.finishedAt && (
                <>
                  <Grid xs={6}>Finished At</Grid>
                  <Grid xs={6} textAlign={'right'}>
                    {moment(generation.result.finishedAt).format('LLL')}
                  </Grid>
                  <Grid xs={4}>Duration</Grid>
                  <Grid xs={8} textAlign={'right'}>
                    {moment(generation.result.finishedAt).diff(
                      moment(generation.result.startedAt),
                      'seconds'
                    )}{' '}
                    Seconds
                  </Grid>
                </>
              )}
            </Grid>
          </Typography>
        )}
        <Typography level="body2">
          <Grid container alignItems={'center'}>
            <Grid xs={12}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Aircraft
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowAircraft(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showAircraft ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showAircraft && (
              <>
                <Grid xs={6}>Name</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.name}
                </Grid>
                <Grid xs={6}>Cruising Speed</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.cruisingSpeed} mph
                </Grid>
                <Grid xs={6}>Cargo Payload</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.cargoPayload} lbs
                </Grid>
                <Grid xs={6}>Time to Takeoff</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.timeToTakeoff} seconds
                </Grid>
                <Grid xs={6}>Time to Refill</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.timeToRefill} seconds
                </Grid>
                <Grid xs={6}>Endurance (Flight Time)</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.endurance} minutes
                </Grid>
                <Grid xs={6}>Wind Tolerance</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.aircraft.windTolerance} mph
                </Grid>
              </>
            )}
            <Grid xs={12} mt={1}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Detection Attributes
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowDetect(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showDetect ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showDetect && (
              <>
                <Grid xs={6}>Name</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.name}
                </Grid>
                <Grid xs={6}>Minimum Fire Size</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.minimumFireSizeSqFt}{' '}
                  SqFt
                </Grid>
                <Grid xs={6}>Detection Latency</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.detectionLatencySeconds}{' '}
                  seconds
                </Grid>
                <Grid xs={6}>Category</Grid>
                <Grid xs={6} textAlign="right">
                  {generation.input.detectionAttributes.category}
                </Grid>
              </>
            )}
            <Grid xs={12} mt={1}>
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Typography level="body1" fontWeight={700}>
                  Control Line Attributes
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color="neutral"
                  onClick={() => setShowControl(bool => !bool)}
                >
                  <KeyboardArrowDown
                    sx={{
                      transform: showControl ? 'initial' : 'rotate(-90deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Grid>
            {showControl &&
              Object.entries(generation.input.controlLineAttributes)
                .sort()
                .map(([key, value]) => (
                  <>
                    <Grid xs={12} fontWeight={700}>
                      {_.startCase(key)}
                    </Grid>
                    <Grid xs={6}>Control Line Width</Grid>
                    <Grid xs={6} textAlign="right">
                      {value.controlLineWidthFt} Ft
                    </Grid>
                    <Grid xs={6}>Coverage Concentration</Grid>
                    <Grid xs={6} textAlign="right">
                      {value.coverageConcentrationG100SqFt} G100SqFt
                    </Grid>
                  </>
                ))}
          </Grid>
        </Typography>
        <Box sx={{display: 'flex', gap: 2, mt: 2}}>
          <Button
            type={'button'}
            size={'sm'}
            color={
              generation.status === 'DONE'
                ? 'success'
                : generation.status === 'PAUSED'
                ? 'neutral'
                : 'warning'
            }
            variant={'soft'}
            disabled={isLoading}
            onClick={() => {
              doLoadDataFromGeneration(generation.id);
            }}
          >
            Load Region
          </Button>
          {['INPROGRESS', 'PENDING', 'PAUSED'].includes(generation.status) && (
            <Button
              type={'button'}
              size={'sm'}
              color={generation.status === 'PAUSED' ? 'neutral' : 'warning'}
              variant={'soft'}
              disabled={isLoading}
              onClick={() => {
                generation.status === 'PAUSED'
                  ? updateStatus(generation.id, 'PENDING')
                  : updateStatus(generation.id, 'PAUSED');
              }}
            >
              {generation.status === 'PAUSED' ? 'Resume Task' : 'Pause Task'}
            </Button>
          )}
          <Button
            type={'button'}
            size={'sm'}
            color={'danger'}
            variant={'soft'}
            disabled={isLoading}
            onClick={event => handleDelete(generation.id)}
            sx={{marginLeft: 'auto'}}
          >
            Delete Region
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};
