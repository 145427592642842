import {useState} from 'react';
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import CloseIcon from '@mui/icons-material/Close';
import {Card, Chip, Option, Select, Tooltip} from '@mui/joy';
import {
  INTERACTION_MODE,
  IgnitionCoverageResponseType,
  IgnitionCoverageType,
  SimulatorContext,
} from '../../context/SimulatorContext';
import {formatCurrencyUSD} from '../../helper/stringFormatting';
import axios from 'axios';
import {ApiConfig} from '../../config/api';
import moment from 'moment';
import toast from 'react-hot-toast';

const REFILL_OPTIONS = [0, 1, 2, 3, 4, 5];

export default function SimulationSidePane() {
  const [isLoading, setIsLoading] = useState(false);
  const [simulatedFires] = SimulatorContext.useStore(
    store => store.simulatedFires
  );
  const fires = simulatedFires.map((fire, index) => ({
    id: (index + 1).toString(),
    lat: fire.lat,
    lng: fire.lng,
  }));
  const [selectedFire, setSelectedFire] = useState(fires[0]?.id || '');
  const [refillAttempt, setRefillAttempt] = useState(0);
  const [stations] = SimulatorContext.useStore(store => store.stations);
  const [selectedAircraftName] = SimulatorContext.useStore(
    store => store.selectedAircraftName
  );
  const [aircraftOptions] = SimulatorContext.useStore(
    store => store.aircraftOptions
  );
  const [selectedCheckpoint, setSelectedCheckpoint] = SimulatorContext.useStore(
    store => store.selectedCheckpoint
  );
  const [ignitionCoverage, setIgnitionCoverage] = SimulatorContext.useStore(
    store => store.ignitionCoverage
  );
  const [interactionMode, setInteractionMode] = SimulatorContext.useStore(
    store => store.interactionMode
  );
  const [store, setStore] = SimulatorContext.useStore(store => store);
  const selectedAircraft = aircraftOptions[selectedAircraftName];

  const isDisabled =
    Object.values(stations).length === 0 || selectedFire === '' || isLoading;

  const handleSubmit = async () => {
    setIsLoading(true);
    const installation = Object.values(stations).map(station => ({
      id: station.id,
      location: station.location,
      aircraftCount: station.aircraftCount || 1,
    }));
    const ignition = {
      lat: fires.find(fire => fire.id === selectedFire)?.lat ?? 0,
      lng: fires.find(fire => fire.id === selectedFire)?.lng ?? 0,
    };
    const aircraft = {
      cruisingSpeedKmh:
        selectedAircraft.cruisingSpeed /* mph to km/h */ * 1.60934,
      timeToTakeoff: selectedAircraft.timeToTakeoff,
      timeToRefill: selectedAircraft.timeToRefill,
      payloadLiters: selectedAircraft.cargoPayload /* lbs to Liters */ / 2.2,
      payloadKilograms: selectedAircraft.cargoPayload /* lbs */ * 0.453592,
      acquisitionCost: selectedAircraft.acquisitionCost,
      annualFixedCost: selectedAircraft.annualFixedCost,
      hourlyOperationCost: selectedAircraft.hourlyOperationCost,
    };
    try {
      const url = ApiConfig.SimulationRun();
      const token = window.localStorage.getItem('RAIN-ISIM-TOKEN');
      const response = await axios.post(
        url,
        {
          ignition,
          installation,
          aircraft,
          refillAttempt,
          controlLineAttributes: store.controlLineAttributes,
          detectionAttributes: store.activeDetectionSystems[0],
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const ignitionCoverage: IgnitionCoverageType = response.data.data;
      setIgnitionCoverage({ignitionCoverage: ignitionCoverage});
      if (ignitionCoverage?.response?.length > 0) {
        handleSelectCheckpoint(ignitionCoverage.response[0]);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      toast.error('Failed fetching data: ' + errorMessage);
      console.error(
        'Error occurred while fetching ignition coverage details',
        error
      );
    }
    setIsLoading(false);
  };

  const handleSelectCheckpoint = (checkpoint: IgnitionCoverageResponseType) => {
    setSelectedCheckpoint({selectedCheckpoint: checkpoint});
    setInteractionMode({interactionMode: INTERACTION_MODE.SIMULATION});
  };

  const handleFireChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    const fire = fires.find(fire => fire.id === value);
    if (fire) {
      setSelectedFire(fire.id);
      setIgnitionCoverage({ignitionCoverage: null});
      setSelectedCheckpoint({selectedCheckpoint: null});
    }
  };

  const onClose = () =>
    setStore({
      ...store,
      activeMenu: null,
    });

  return (
    <Box
      sx={{
        p: 2,
        pl: 1,
        maxHeight: '85vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Simulation
        </Typography>
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>
      <Grid container alignItems={'center'} rowGap={1}>
        <Grid xs={6}>
          <Typography level="body2" fontWeight={700}>
            Simulated Fire
          </Typography>
        </Grid>
        <Grid xs={6} ml={'auto'}>
          <Select
            disabled={fires.length === 0}
            placeholder="Select Fire..."
            onChange={handleFireChange}
            value={selectedFire}
          >
            {fires.map(fire => (
              <Option key={fire.id} value={fire.id}>
                {`Fire #${fire.id}`}
              </Option>
            ))}
          </Select>
        </Grid>
        <Grid xs={6}>
          <Tooltip
            title={
              'Determine how many aircraft can simultaneously refill at the nearest station.'
            }
          >
            <Typography level="body2" fontWeight={700}>
              Refill Attempts
            </Typography>
          </Tooltip>
        </Grid>
        <Grid xs={2} ml={'auto'}>
          <Select
            size="sm"
            defaultValue={0}
            placeholder="Choose one…"
            onChange={(_, v) => setRefillAttempt(v || 0)}
          >
            {REFILL_OPTIONS.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Button
        loading={isLoading}
        loadingPosition="start"
        size="sm"
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Run Simulation
      </Button>
      <Box>
        <Typography level="h6" textColor="text.primary">
          Report
        </Typography>
        <Card sx={{mt: 1}}>
          <Typography level="body2">
            <Grid container>
              <Grid xs={12}>
                <strong>Coverage</strong>
              </Grid>
              <Grid xs={6}>Fuel Model</Grid>
              <Grid xs={6} textAlign={'right'} fontWeight={"bold"}>
                {ignitionCoverage?.fuelModel || "-"}
              </Grid>
              <Grid xs={6}>Coverage Level</Grid>
              <Grid xs={6} textAlign={'right'}>
                {ignitionCoverage && (
                  <Chip
                    size="sm"
                    color={
                      ignitionCoverage.coverageLevel === 'CONTAINED'
                        ? 'success'
                        : ignitionCoverage.coverageLevel === 'SLOWED'
                        ? 'primary'
                        : 'neutral'
                    }
                  >
                    {ignitionCoverage.coverageLevel}
                  </Chip>
                )}
              </Grid>
              <Grid xs={6}>Duration</Grid>
              <Grid xs={6} textAlign={'right'}>
                {ignitionCoverage && (
                  <Chip size="sm" color="warning">
                    {moment
                      .utc(ignitionCoverage.totalMinutesSpent * 60 * 1000)
                      .format('HH:mm:ss')}
                  </Chip>
                )}
              </Grid>
              <Grid xs={8}>Fire Size At Containment</Grid>
              <Grid xs={4} textAlign={'right'}>
                {ignitionCoverage &&
                  `${ignitionCoverage.fireSizeAtContainmentSqM?.toFixed(
                    2
                  )} SqM`}
              </Grid>
              <Grid xs={12}>
                <strong>Cost</strong>
              </Grid>
              <Grid xs={6}>Total Acquisition</Grid>
              <Grid xs={6} textAlign={'right'}>
                {ignitionCoverage &&
                  formatCurrencyUSD(
                    ignitionCoverage.cost?.totalAcquisitionCost
                  )}
              </Grid>
              <Grid xs={6}>Total Annual Fixed</Grid>
              <Grid xs={6} textAlign={'right'}>
                {ignitionCoverage &&
                  formatCurrencyUSD(
                    ignitionCoverage.cost?.totalAnnualFixedCost
                  )}
              </Grid>
              <Grid xs={6}>Total Hourly Cost</Grid>
              <Grid xs={6} textAlign={'right'}>
                {ignitionCoverage &&
                  formatCurrencyUSD(
                    ignitionCoverage.cost?.totalHourlyOperationCost
                  )}
              </Grid>
            </Grid>
          </Typography>
        </Card>
      </Box>
      {ignitionCoverage && ignitionCoverage?.response.length > 0 && (
        <>
          <Divider />
          <Box>
            <Typography level="h6" textColor="text.primary">
              Response
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
              {ignitionCoverage.response.map((data, index: number) => {
                return (
                  <Card
                    key={data.id}
                    variant={
                      data.id === selectedCheckpoint?.id ? 'soft' : 'plain'
                    }
                    onClick={() => {
                      handleSelectCheckpoint(ignitionCoverage.response[index]);
                    }}
                    sx={{border: '1px solid white', cursor: 'pointer'}}
                  >
                    <Typography level="body2">
                      <Grid container>
                        <Grid xs={12}>
                          <Typography level="body2" fontWeight={700}>
                            General
                          </Typography>
                        </Grid>
                        <Grid xs={6}>Status</Grid>
                        <Grid xs={6} textAlign={'right'}>
                          {data.label && (
                            <Chip size="sm" color="primary">
                              {data.label}
                            </Chip>
                          )}
                        </Grid>
                        <Grid xs={6}>Timestamp</Grid>
                        <Grid xs={6} textAlign={'right'}>
                          <Chip size="sm" color="warning">
                            {moment
                              .utc(Math.ceil(data.minute * 60) * 1000)
                              .format('HH:mm:ss')}
                          </Chip>
                        </Grid>
                        <Grid xs={12}>
                          <Typography level="body2" fontWeight={700}>
                            Aircraft
                          </Typography>
                        </Grid>
                        <Grid xs={4}>Name</Grid>
                        <Grid xs={8} textAlign={'right'}>
                          {selectedAircraftName}
                        </Grid>
                        {data.station && (
                          <>
                            <Grid xs={12}>
                              <Typography level="body2" fontWeight={700}>
                                Base Origin
                              </Typography>
                            </Grid>
                            <Grid xs={6}>Latitude</Grid>
                            <Grid xs={6} textAlign={'right'}>
                              {data.station?.location.lat.toFixed(5)}
                            </Grid>
                            <Grid xs={6}>Longitude</Grid>
                            <Grid xs={6} textAlign={'right'}>
                              {data.station?.location.lng.toFixed(5)}
                            </Grid>
                            <Grid xs={6}>Refills</Grid>
                            <Grid xs={6} textAlign={'right'}>
                              {data.station?.refillCount ?? 0}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Typography>
                  </Card>
                );
              })}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
