import RainLogo from "./RainLogo.png"

export default function ErrorPage({message}: { message?: string }) {
  return (
    <main style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      color: "white"
    }}>
      <img src={RainLogo} style={{width: "200px"}}/>
      {!!message ? `Error: ${message}` : 'Unknown Error'}
    </main>
  )
}
