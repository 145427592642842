import * as React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import Slider from '@mui/joy/Slider';

// Icons import
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CloseIcon from '@mui/icons-material/Close';
import {
  FuelModel,
  FuelModelToName,
  isBurnable,
} from '../../context/EnvironmentTypes';
import {SimulatorContext} from '../../context/SimulatorContext';

function FuelModelContainmentAttributes({fuelModel}: {fuelModel: FuelModel}) {
  const [controlLineAttributes, setControlLineAttributes] =
    SimulatorContext.useStore(store => store.controlLineAttributes);
  const containmentAttributes = controlLineAttributes[fuelModel];

  const [inputWidth, setInputWidth] = React.useState(
    containmentAttributes?.controlLineWidthFt
  );
  const [inputCoverageLevel, setInputCoverageLevel] = React.useState(
    containmentAttributes?.coverageConcentrationG100SqFt
  );

  const fuelModelName = FuelModelToName[fuelModel];

  return (
    <Box sx={{p: 2}}>
      <Typography level="body2" textColor="text.primary">
        {fuelModel + (fuelModelName ? `: ${fuelModelName}` : '')}
      </Typography>
      <Box sx={{mt: 2}}>
        <Typography level="body3" textColor="text.primary">
          Control Line Width (ft)
        </Typography>
        <Box sx={{mt: 3, display: 'flex', gap: 1}}>
          <Slider
            valueLabelFormat={value => `${value} ft`}
            value={inputWidth}
            onChange={(evt, value) => {
              if (Array.isArray(value)) {
                return;
              }

              setInputWidth(value);
            }}
            onChangeCommitted={(evt, value) => {
              if (Array.isArray(value)) {
                return;
              }
              setControlLineAttributes({
                controlLineAttributes: {
                  ...controlLineAttributes,
                  [fuelModel]: {
                    ...containmentAttributes,
                    controlLineWidthFt: value,
                  },
                },
              });
            }}
            step={1}
            min={1}
            max={50}
            valueLabelDisplay="on"
          />
        </Box>
        <Typography level="body3" textColor="text.primary">
          Coverage Level (gallons per 100 sqft)
        </Typography>
        <Box sx={{mt: 3, display: 'flex', gap: 1}}>
          <Slider
            // valueLabelFormat={value => `${value} gal/100 sqft`}
            value={inputCoverageLevel}
            onChange={(evt, value) => {
              if (Array.isArray(value)) {
                return;
              }

              setInputCoverageLevel(value);
            }}
            onChangeCommitted={(evt, value) => {
              if (Array.isArray(value)) {
                return;
              }
              setControlLineAttributes({
                controlLineAttributes: {
                  ...controlLineAttributes,
                  [fuelModel]: {
                    ...containmentAttributes,
                    coverageConcentrationG100SqFt: value,
                  },
                },
              });
            }}
            step={0.5}
            min={0.5}
            max={12}
            valueLabelDisplay="on"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function ContainmentSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  const burnableFuels = Object.values(FuelModel).filter(isBurnable);

  const fuelContainmentConfig = burnableFuels.map(fuelModel =>
    FuelModelContainmentAttributes({fuelModel})
  );

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })

  return (
    <Box
      sx={{
        maxHeight: '85vh',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Factors influencing fire spread
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body2" textColor="text.primary">
            Select weather conditions
          </Typography>
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{'--IconButton-size': '24px'}}
          >
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
        <Box sx={{p: 2}}>
          <Typography level="body3" textColor="text.primary">
            Local Wind Condition Coverage
          </Typography>
          <Box sx={{mt: 3, display: 'flex', gap: 1}}>
            <Slider
              disabled={true}
              valueLabelFormat={value => `${value}%`}
              defaultValue={70}
              step={5}
              min={5}
              max={100}
              valueLabelDisplay="on"
            />
          </Box>

          {/* <Typography level="body4" textColor="text.primary">
            Camp Fire (45 lives, 100 homes, 800k acres) occured in 80-percentile
            local wind conditions.
          </Typography> */}
        </Box>
        {/* <Box sx={{mt: 2}}>
          <Typography level="body2" textColor="text.primary">
            Fire spread model
          </Typography>

          <Autocomplete
            placeholder="USFS forward rate of spread"
            options={[
              // some of Thailand provinces
              'USFS forward rate of spread',
              'QUIC Fire',
              'Rain physics-based model',
            ]}
          />
        </Box> */}
      </Box>
      {/* <Divider /><Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  ALERTWildfire
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
              <Autocomplete
                  placeholder="Position, skills, etc…"
                  options={[
                      // some of Thailand provinces
                      'Bangkok',
                      'Amnat Charoen',
                      'Ang Thong',
                      'Bueng Kan',
                      'Buriram',
                      'Chachoengsao',
                      'Chai Nat',
                      'Chaiyaphum',
                      'Chanthaburi',
                      'Chiang Mai',
                      'Chiang Rai',
                      'Chonburi',
                  ]} />
              <Typography level="body3" textColor="text.primary">
                Smallest detectable fire
              </Typography>
              <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                  <Slider
                      valueLabelFormat={(value) => `${value} sqft`}
                      defaultValue={10}
                      step={20}
                      min={10}
                      max={10000}
                      valueLabelDisplay="on" />
              </Box>
              <Typography level="body3" textColor="text.primary">
                Latency to observe/communicate/confirm detection
              </Typography>
              <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                  <Slider
                      valueLabelFormat={(value) => `${value} seconds`}
                      defaultValue={10}
                      step={10}
                      min={0}
                      max={300}
                      valueLabelDisplay="on" />
              </Box>
          </Box>
      </Box>
      <Divider /> */}
      {/* <Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Education
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
              <RadioGroup name="education" defaultValue="any">
                  <Radio label="Any" value="any" size="sm" />
                  <Radio label="High School" value="high-school" size="sm" />
                  <Radio label="College" value="college" size="sm" />
                  <Radio label="Post-graduate" value="post-graduate" size="sm" />
              </RadioGroup>
          </Box>
      </Box><Divider /><Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Previous experience
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
      </Box> */}

      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Factors influencing fire containment
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
      </Box>
      {fuelContainmentConfig}
      {/* <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body2" textColor="text.primary">
            Select suppression system
          </Typography>
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{'--IconButton-size': '24px'}}
          >
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
      </Box> */}
    </Box>
  );
}
