/**
 * @description Format number to currency with USD symbol
 * @param nominal
 * @returns
 */
export const formatCurrencyUSD = (nominal: number) => {
  if (isNaN(nominal) || nominal === null || nominal === undefined) {
    return '';
  }
  const formattedNominal = Math.ceil(nominal)
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '$' + formattedNominal;
};
