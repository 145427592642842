import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Box} from '@mui/joy';

const LoadConfirmationModal = ({
  isOpen,
  onClose,
  handleLoadConflict,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleLoadConflict: (props: {isReplace: boolean}) => void;
}) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(0px)',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{m: 1}} />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Load Installation
        </Typography>
        <Typography id="modal-desc" textColor="text.tertiary" mb={2}>
          Would you like to <strong>replace</strong> your existing installation
          or <strong>combine</strong> the installations?
        </Typography>
        <Box sx={{display: 'flex', gap: 2}}>
          <Button
            variant="outlined"
            onClick={() => handleLoadConflict({isReplace: true})}
          >
            <Typography level="body3">Replace</Typography>
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleLoadConflict({isReplace: false})}
          >
            <Typography level="body3">Combine</Typography>
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default LoadConfirmationModal;
