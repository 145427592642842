import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {AircraftAttributes, SimulatorContext, Station} from '../../context/SimulatorContext';
import * as turf from '@turf/turf';
import { AircraftType } from './InstallationSidePane';
import {Card} from '@mui/joy';

  type PayloadDeliveryDetails = {
    arrivalMinute: number;
    cumulativePayload: number;
  aircraftTypes: AircraftType[];
};

function FireInfoBlock({
  fire,
  stations,
  aircraftName,
  aircraftAttributes,
  onDelete,
}: {
  fire: {lat: number; lng: number};
  stations: Station[];
  aircraftName: string;
  aircraftAttributes: AircraftAttributes;
  onDelete: (fire: any) => any;
}) {
  const arrivalTimePayload = stations
    .map(station => {
      const aircraftDistanceToFire = turf.distance(
        [station.location.lng, station.location.lat],
        [fire.lng, fire.lat],
        {units: 'miles'}
      );

      return {
        ...station,
        aircraftFlightTimeMins: aircraftDistanceToFire / aircraftAttributes.cruisingSpeed * 60,
      }
    })
    .filter(station => station.aircraftFlightTimeMins < 20)
    .sort((a, b) => a.aircraftFlightTimeMins - b.aircraftFlightTimeMins)
    .reduce((accum: PayloadDeliveryDetails[], station, currentIndex) => {
      const roundedArrival = Math.ceil(
        aircraftAttributes.timeToTakeoff / 60 +
        station.aircraftFlightTimeMins);


      if (accum.length === 0) {
        accum.push({
          arrivalMinute: roundedArrival,
          cumulativePayload: aircraftAttributes.cargoPayload,
          aircraftTypes: [{
            aircraftName: aircraftName,
            aircraftAttributes: aircraftAttributes,
          }],
        });
      } else if (roundedArrival > accum[accum.length - 1].arrivalMinute) {
        const lastPayload = accum[accum.length - 1]
        accum.push({
          arrivalMinute: roundedArrival,
          cumulativePayload: aircraftAttributes.cargoPayload +
            lastPayload.cumulativePayload,
          aircraftTypes: [
            ...lastPayload.aircraftTypes,
            {
              aircraftName: aircraftName,
              aircraftAttributes: aircraftAttributes,
            },
          ],
        });
      } else {
        accum[accum.length - 1].cumulativePayload += aircraftAttributes.cargoPayload;
        accum[accum.length - 1].aircraftTypes = [
          ...accum[accum.length - 1].aircraftTypes,
          {aircraftName, aircraftAttributes},
        ];
      }

      return accum;
    }, []);

    // .reduce((accum, station) => {
    //   const roundedArrival = Math.ceil(
    //     aircraftAttributes.timeToTakeoff / 60 +
    //     station.aircraftFlightTimeMins);

    //   if (accum.has(roundedArrival)) {
    //     const existingPayload = accum.get(roundedArrival)
    //     accum.set(roundedArrival, existingPayload + aircraftAttributes.cargoPayload)
    //   } else {
    //     accum.set(roundedArrival, aircraftAttributes.cargoPayload)
    //   }

    //   return accum;
    // }, new Map());

  return (
    <Card color="primary" sx={{border: '1px solid white'}}>
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <>
            <Typography level="body1" textColor="text.primary">
              Fire&nbsp;
              <Typography level="body5" textColor="text.primary">
                {!Number.isNaN(fire.lat) && !Number.isNaN(fire.lng)
                  ? `${fire.lat.toFixed(5)}, ${fire.lng.toFixed(5)}`
                  : ''}
              </Typography>
            </Typography>
          </>

          <IconButton
            size="sm"
            variant="plain"
            color="danger"
            sx={{'--IconButton-size': '24px'}}
            onClick={() => onDelete(fire)}
          >
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
          {/* Action button - remove fire */}
        </Box>

        {/* Attributes of fire - location name */}
        {/* Attributes of fire - agency responsible */}

        <Box sx={{mt: 2}}>
          <Typography level="body2" textColor="text.primary">
            Response details
          </Typography>
          {arrivalTimePayload.map(delivery => {
            return (
              <Box>
                <Typography level="body2">
                  <strong>{Math.floor(delivery.cumulativePayload / 8.34)} gallons:&nbsp;</strong>
                  {delivery.arrivalMinute} minutes
                </Typography>
              </Box>
            );
          })}

          {/* {Array.from(arrivalTimePayload.keys()).map(minute => {
            return (<>
              <strong>{arrivalTimePayload.get(minute)} lbs:</strong>
              <em>minute</em>
            </>)
          })} */}
          {/* 3 min, 900 gallons, 1/3 acre, 48% contained */}
          {/* </Box> */}
        </Box>
      </Box>
    </Card>
  );
}


export default function SimulatedFiresSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  // TODO: List active fire locations
  // On hover item, it should highlight the fire (2x magnification?)
  // On delete, it should remove

  const [simulatedFires, setSimulatedFires] =
    SimulatorContext.useStore(store => store.simulatedFires)

  const [selectedAircraftName] =
    SimulatorContext.useStore(store => store.selectedAircraftName)

  const [aircraftOptions] =
    SimulatorContext.useStore(store => store.aircraftOptions)

  const aircraftAttributes = aircraftOptions[selectedAircraftName]

  function deleteFire(fireToDelete: any) {
    setSimulatedFires({
      simulatedFires: simulatedFires.filter(fire => {
        return !(fire.lat === fireToDelete.lat && fire.lng === fireToDelete.lng);
      })
    })
  }

  const [stations] = SimulatorContext.useStore(store => store.stations)

  // const [activeDetectionSystems, setActiveDetectionSystems] =
  //   SimulatorContext.useStore(store => store.activeDetectionSystems);

  // const [inputMinimumFireSizeSqFt, setInputMinimumFireSizeSqFt] =
  //   React.useState(activeDetectionSystems[0].minimumFireSizeSqFt);

  // const [inputLatency, setInputLatency] = React.useState(
  //   activeDetectionSystems[0].detectionLatencySeconds
  // );

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })

  return (
    <Box
      sx={{
        maxHeight: '85vh',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Review simulated ignitions
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        {simulatedFires.map(fire =>
          FireInfoBlock({
            fire,
            stations: Object.values(stations),
            aircraftName: selectedAircraftName,
            aircraftAttributes: aircraftOptions[selectedAircraftName],
            onDelete: deleteFire,
          })
        )}
      </Box>
      <Divider />
      {/* <Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Education
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
              <RadioGroup name="education" defaultValue="any">
                  <Radio label="Any" value="any" size="sm" />
                  <Radio label="High School" value="high-school" size="sm" />
                  <Radio label="College" value="college" size="sm" />
                  <Radio label="Post-graduate" value="post-graduate" size="sm" />
              </RadioGroup>
          </Box>
      </Box><Divider /><Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Previous experience
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
      </Box> */}
    </Box>
  );
}
