import RainLogo from "./RainLogo.png"
import {PuffLoader} from "react-spinners";

export default function LoadingPage() {
  return (
    <main style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "black",
      color: "white",
      gap: "10px"
    }}>
      <img src={RainLogo} style={{width: "200px"}}/>
      <PuffLoader color={"white"}/>
    </main>
  )
}
