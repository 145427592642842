import * as React from "react";
import {ReactNode} from "react";
import {SimulatorContext} from "../../context/SimulatorContext";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import Chip from "@mui/joy/Chip";

interface NavItemProps {
  mode: string,
  icon: JSX.Element,
  isBeta?: boolean,
  content: ReactNode,
  map?: "visible" | "invisible"
}

export function NavItem(
  props: NavItemProps
) {
  const [store, setStore] = SimulatorContext.useStore(store => store)
  const {mode, icon, isBeta = false, content, map = "visible"} = props

  if (store.activeMenu?.mode !== mode) {
    return (
      <ListItem id={'nav-item-' + mode + '-selected'}>
        <ListItemButton onClick={() => setStore({
          ...store,
          activeMenu: {
            mode: mode,
            content: content,
            isMapVisible: !map || map === "visible"
          }
        })}>
          <ListItemDecorator sx={{color: 'neutral.500'}}>
            {icon}
          </ListItemDecorator>
          <ListItemContent>{mode}</ListItemContent>
          {isBeta && (
            <Chip
              variant="soft"
              color="info"
              size="sm"
              sx={{borderRadius: 'sm'}}
            >
              Beta
            </Chip>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem id={'nav-item-' + mode}>
      <ListItemButton
        variant="soft"
        color="primary"
        onClick={() => setStore({
          ...store,
          activeMenu: null
        })}
      >
        <ListItemDecorator sx={{color: 'inherit'}}>{icon}</ListItemDecorator>
        <ListItemContent>{mode}</ListItemContent>
        {isBeta && (
          <Chip variant="soft" color="info" size="sm" sx={{borderRadius: 'sm'}}>
            Beta
          </Chip>
        )}
      </ListItemButton>
    </ListItem>
  );
}
