import * as React from 'react';
import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import './App.css'

import teamTheme from './theme';
import {SimulatorContext} from './context/SimulatorContext';
import {Toaster} from "react-hot-toast";
import {PageContent} from "./layout/BaseLayout";

export default function App() {
  return (
    <CssVarsProvider disableTransitionOnChange theme={teamTheme}>
      <SimulatorContext.Provider>
        <CssBaseline/>
        <PageContent/>
        <Toaster/>
      </SimulatorContext.Provider>
    </CssVarsProvider>
  );
}
