import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import {useMemo, useState} from 'react';
import {Card, Chip, LinearProgress} from '@mui/joy';
import {GeneratedRegionType} from '../../data/InstallationTypes';
import CountdownTimer from '../CountdownText';
import {Grid} from '@mui/material';
import moment from 'moment';
import {GenerationDetailModal} from '../modals/GenerationDetailModal';

export const GenerationCard = ({
  generation,
  mutate,
  isActive,
  setActiveGeneration,
}: {
  generation: GeneratedRegionType;
  mutate: () => void;
  isActive: boolean;
  setActiveGeneration: any;
}) => {
  const installationsCount = generation.result?.installationsCount || 0;
  const [showDetailModal, setShowDetailModal] = useState(false);

  const kageki = useMemo(() => {
    return {
      percentage:
        generation.status === 'DONE'
          ? 100
          : generation.result?.percentage || 0,
      processed: generation.result?.processed || 0,
      total: generation.result?.total || 0,
      eta: 0,
    };
  }, [generation]);

  return (
    <>
      <GenerationDetailModal
        generation={generation}
        isOpen={showDetailModal}
        onClose={() => setShowDetailModal(false)}
        mutate={mutate}
        setActiveGeneration={setActiveGeneration}
      />
      <Card
        onClick={() => setShowDetailModal(true)}
        sx={{
          marginBottom: '10px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'neutral.100',
          },
        }}
        color="primary"
        variant={isActive ? 'soft' : 'plain'}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <Typography level="body2">
            <Grid container>
              <Grid xs={12}>
                <Typography level="body2" fontWeight={700}>
                  {generation.name}
                </Typography>
              </Grid>
              <Grid xs={6}>Status</Grid>
              <Grid xs={6} textAlign={'right'}>
                <Typography
                  level={'body2'}
                  fontWeight={'bold'}
                  color={
                    generation.status === 'DONE'
                      ? 'success'
                      : generation.status === 'PAUSED'
                      ? 'neutral'
                      : 'warning'
                  }
                >
                  {generation.status}
                </Typography>
              </Grid>
              <Grid xs={6}>Strategy</Grid>
              <Grid xs={6} textAlign={'right'}>
                <Chip size="sm" color={'info'}>
                  {generation.strategy}
                </Chip>
              </Grid>
              <Grid xs={8}>Installations</Grid>
              <Grid xs={4} textAlign={'right'}>
                {installationsCount} Station
              </Grid>
              <Grid xs={4}>Progress</Grid>
              <Grid xs={8} textAlign={'right'}>
                {kageki.processed} of {kageki.total} tiles
              </Grid>
              {generation.result?.startedAt && (
                <>
                  <Grid xs={4}>Started At</Grid>
                  <Grid xs={8} textAlign={'right'}>
                    {moment(generation.result.startedAt).format('LLL')}
                  </Grid>
                </>
              )}
              {generation.result?.finishedAt && (
                <>
                  <Grid xs={4}>Finished At</Grid>
                  <Grid xs={8} textAlign={'right'}>
                    {moment(generation.result.finishedAt).format('LLL')}
                  </Grid>
                </>
              )}
            </Grid>
          </Typography>
          <LinearProgress
            determinate
            variant={generation.status === 'DONE' ? 'soft' : 'solid'}
            color={
              generation.status === 'DONE'
                ? 'success'
                : generation.status === 'PAUSED'
                ? 'neutral'
                : 'warning'
            }
            size="sm"
            thickness={32}
            value={kageki.percentage}
            sx={{
              flexGrow: 1,
              width: '100%',
              boxShadow: 'sm',
              borderColor: 'neutral.500',
            }}
          >
            <Typography
              level={'body3'}
              fontWeight="md"
              textColor={generation.status === 'PAUSED' ? 'black' : 'white'}
              sx={{zIndex: 0, textWrap: 'nowrap'}}
            >
              {generation.status === 'DONE'
                ? 'Generation Completed'
                : generation.status === 'PAUSED'
                ? 'Generation Paused'
                : generation.status === 'PENDING'
                ? 'Generation Pending'
                : `Generating Installation ${kageki.percentage}%`}
            </Typography>
          </LinearProgress>
          <Box>
            <Typography
              level={'body3'}
              color={'warning'}
              sx={{width: '100%'}}
              textAlign={'center'}
            >
              {generation.status === 'PENDING' ? (
                <>Waiting for other generations to complete...</>
              ) : generation.status === 'INPROGRESS' ? (
                <>
                  {`Processing ${kageki.processed} of ${kageki.total} tiles`}
                  {/* Estimate completed in{' '}
                <CountdownTimer key={kageki.eta} seconds={kageki.eta} /> */}
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
};
