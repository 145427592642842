import {Container, FormControl, FormLabel, Input, Option, Select} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {FormEvent, useState} from "react";
import {SimulatorContext} from "../../context/SimulatorContext";
import styled from '@emotion/styled';
import {TableBody} from "@mui/material";
import {mutate} from "swr";
import axios, {AxiosError} from "axios";
import toast from "react-hot-toast";
import LoadingPage from "../../pages/LoadingPage";
import ErrorPage from "../../pages/ErrorPage";
import Modal from '@mui/joy/Modal';
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import Swal from 'sweetalert2'
import {useUserProfile} from "../../hooks/useUserProfile";
import useUserList from "../../hooks/useUserList";

const TableResponsive = styled.div`
  overflow-x: auto;
  font-size: 14px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
  background-color: inherit;
`;

const TableCell = styled.td`
  padding: 0.25rem;
  vertical-align: middle;
`;

const TableHeaderCell = styled.th`
  padding: 0.25rem;
  vertical-align: top;
  border-top: 2px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
  color: inherit;
`;

interface RainTableProps {
  children: React.ReactNode;
}

const RainTable: React.FC<RainTableProps> = ({children}) => {
  return (
    <TableResponsive>
      <Table>{children}</Table>
    </TableResponsive>
  );
};

interface User {
  name: string,
  email: string,
  id: string,
  username: string,
  role: string
}

export default function UserManagementSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)
  const [createModalOpened, setCreateModalOpened] = useState(false)
  const [updateModalForm, setUpdateModalForm] = useState<User | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {data: userProfile} = useUserProfile()
  const {data: users, isLoading, error, mutate: mutateUsers} = useUserList()

  if (error) return <ErrorPage/>
  if (!users || isLoading) return <LoadingPage/>

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })

  async function doCreateNewUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const token = window.localStorage.getItem("RAIN-ISIM-TOKEN")
    if (!token) {
      window.localStorage.removeItem("RAIN-ISIM-TOKEN")
      await mutate("apiToken")
      toast.success("Session expired. Please login.")
      return;
    }

    const formData = new FormData(event.target as HTMLFormElement);

    // Initialize an empty object to store key-value pairs
    const formValues: { [key: string]: string } = {};

    // Iterate through the FormData entries and populate the formValues object
    for (const [key, value] of formData.entries()) {
      formValues[key] = value.toString();
    }

    setIsSubmitting(true)

    await axios.post(
      `${process.env.REACT_APP_BASE_API}/api/v1/user/register`,
      formValues,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }).then(() => {
      setCreateModalOpened(false)
      toast.success("New user registered successfully");
      (event.target as HTMLFormElement).reset()
      mutateUsers()
    }).catch((reason: AxiosError) => {
      const message = (reason.response?.data as any)?.detail?.message;
      if (message) {
        toast.error(message)
      }

      if (reason.response?.status === 401) {
        window.localStorage.removeItem("RAIN-ISIM-TOKEN")
        mutate("apiToken")
        toast.error("Session expired. Please login.")
      }

      return null;
    })

    setIsSubmitting(false)
  }

  async function doUpdateUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!updateModalForm) return;

    const token = window.localStorage.getItem("RAIN-ISIM-TOKEN")
    if (!token) {
      window.localStorage.removeItem("RAIN-ISIM-TOKEN")
      await mutate("apiToken")
      toast.error("Session expired. Please login.")
      return;
    }

    const formData = new FormData(event.target as HTMLFormElement);

    // Initialize an empty object to store key-value pairs
    const formValues: { [key: string]: string } = {};

    // Iterate through the FormData entries and populate the formValues object
    for (const [key, value] of formData.entries()) {
      formValues[key] = value.toString();
    }

    setIsSubmitting(true)

    await axios.patch(
      `${process.env.REACT_APP_BASE_API}/api/v1/user/update/` + updateModalForm.id,
      formValues,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }).then(() => {
      setUpdateModalForm(null)
      toast.success("User updated successfully");
      (event.target as HTMLFormElement).reset()
      mutateUsers()
    }).catch((reason: AxiosError) => {
      const message = (reason.response?.data as any)?.detail?.message;
      if (message) {
        toast.error(message)
      }

      if (reason.response?.status === 401) {
        window.localStorage.removeItem("RAIN-ISIM-TOKEN")
        mutate("apiToken")
        toast.error("Session expired. Please login.")
      }

      return null;
    })

    setIsSubmitting(false)
  }

  async function doDeleteUser(user_id: string) {
    const token = window.localStorage.getItem("RAIN-ISIM-TOKEN")
    if (!token) {
      window.localStorage.removeItem("RAIN-ISIM-TOKEN")
      await mutate("apiToken")
      toast.success("Session expired. Please login.")
      return;
    }

    setIsSubmitting(true)
    await axios.delete(
      `${process.env.REACT_APP_BASE_API}/api/v1/user/delete/${user_id}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }).then(() => {
      toast.success("User deleted successfully");
      mutateUsers()
    }).catch((reason: AxiosError) => {
      const message = (reason.response?.data as any)?.detail?.message;
      if (message) {
        toast.error(message)
      }

      if (reason.response?.status === 401) {
        window.localStorage.removeItem("RAIN-ISIM-TOKEN")
        mutate("apiToken")
        toast.error("Session expired. Please login.")
      }

      return null;
    })

    setIsSubmitting(false)
  }

  // @ts-ignore
  return (
    <Container
      sx={{
        pl: "8px !important",
        maxHeight: '85vh',
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          User Management
        </Typography>
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose}/>
        </Button>
      </Box>

      <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "5px"}}>
        <Button size="sm" variant="solid" onClick={() => setCreateModalOpened(prev => !prev)} style={{
          borderRadius: "3px"
        }}>
          <AddIcon/> Register New User
        </Button>
      </div>

      <Modal
        open={createModalOpened}
        onClose={() => setCreateModalOpened(prev => !prev)}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: 400,
            maxWidth: "90%",
            borderRadius: "3px",
            p: 2,
            boxShadow: 'sm',
          }}
        >
          <ModalClose variant="plain" sx={{m: 1}}/>

          <form onSubmit={doCreateNewUser}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Register New User
            </Typography>

            <FormControl style={{
              marginBottom: "10px"
            }}>
              <FormLabel required>Name</FormLabel>
              <Input required
                     color="neutral"
                     size="sm"
                     disabled={isSubmitting}
                     name={"name"}
                     variant="outlined"
                     type={"text"}
              />
            </FormControl>

            <FormControl style={{
              marginBottom: "10px"
            }}>
              <FormLabel required>Username</FormLabel>
              <Input required
                     color="neutral"
                     size="sm"
                     disabled={isSubmitting}
                     name={"username"}
                     variant="outlined"
                     type={"text"}
              />
            </FormControl>

            <FormControl style={{
              marginBottom: "10px"
            }}>
              <FormLabel required>Email</FormLabel>
              <Input required
                     color="neutral"
                     size="sm"
                     disabled={isSubmitting}
                     name={"email"}
                     variant="outlined"
                     type={"email"}
              />
            </FormControl>

            <FormControl style={{
              marginBottom: "10px"
            }}>
              <FormLabel required>Password</FormLabel>
              <Input required
                     color="neutral"
                     size="sm"
                     disabled={isSubmitting}
                     name={"password"}
                     variant="outlined"
                     type={"password"}
              />
            </FormControl>

            {userProfile?.role === "SUPER-ADMIN" &&
              <FormControl style={{
                marginBottom: "10px"
              }}>
                <FormLabel required>Role</FormLabel>
                {/* @ts-ignore */}
                <Select required
                        disabled={isSubmitting} name={"role"} size={"sm"} placeholder="Choose one…"
                        defaultValue={"USER"}>
                  <Option value={"SUPER-ADMIN"}>Super Admin</Option>
                  <Option value={"USER-ADMIN"}>User Admin</Option>
                  <Option value={"USER"}>User</Option>
                </Select>
              </FormControl>
            }

            <div style={{textAlign: "center"}}>
              <Button disabled={isSubmitting} type={"submit"} variant={"solid"} size={"sm"} style={{marginTop: "5px"}}
                      color={"primary"}>
                Save
              </Button>
            </div>
          </form>
        </Sheet>
      </Modal>


      <Modal
        open={!!updateModalForm}
        onClose={() => setUpdateModalForm(null)}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: 400,
            maxWidth: "90%",
            borderRadius: "3px",
            p: 2,
            boxShadow: 'sm',
          }}
        >
          <ModalClose variant="plain" sx={{m: 1}}/>

          <form onSubmit={doUpdateUser}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Update User
            </Typography>

            <FormControl style={{
              marginBottom: "10px"
            }}>
              <FormLabel required>Name</FormLabel>
              <Input required
                     color="neutral"
                     size="sm"
                     defaultValue={updateModalForm?.name}
                     disabled={isSubmitting}
                     name={"name"}
                     variant="outlined"
                     type={"text"}
              />
            </FormControl>

            {(userProfile && userProfile.id !== updateModalForm?.id && userProfile?.role === "USER-ADMIN") &&
              <FormControl style={{
                marginBottom: "10px"
              }}>
                <FormLabel required>Role</FormLabel>
                {/* @ts-ignore */}
                <Select required
                        disabled={isSubmitting} name={"role"} size={"sm"} placeholder="Choose one…"
                        defaultValue={updateModalForm?.role}>
                  <Option value={"SUPER-ADMIN"}>Super Admin</Option>
                  <Option value={"USER-ADMIN"}>User Admin</Option>
                  <Option value={"USER"}>User</Option>
                </Select>
              </FormControl>
            }

            <div style={{textAlign: "center"}}>
              <Button disabled={isSubmitting} type={"submit"} variant={"solid"} size={"sm"} style={{marginTop: "5px"}}
                      color={"primary"}>
                Save
              </Button>
            </div>
          </form>
        </Sheet>
      </Modal>

      <RainTable>
        <thead>
        <TableRow>
          <TableHeaderCell>No</TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Username</TableHeaderCell>
          <TableHeaderCell>Email</TableHeaderCell>
          <TableHeaderCell>Role</TableHeaderCell>
          <TableHeaderCell>Action</TableHeaderCell>
        </TableRow>
        </thead>
        <TableBody>
          {users.map((user, idx) => {
            return (
              <TableRow key={user.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role.split("-").map(e => e[0].toUpperCase() + e.slice(1).toLowerCase()).join(" ")}</TableCell>
                <TableCell style={{display: "flex", gap: "6px"}}>
                  <Button size="sm" color={"warning"}
                          onClick={() => setUpdateModalForm({...user})}
                          style={{
                            padding: "5px",
                            borderRadius: "3px"
                          }}>
                    <EditIcon fontSize={"medium"}/>
                  </Button>
                  {(userProfile && userProfile.id !== user.id) &&
                    <Button size="sm" color={"danger"} style={{
                      padding: "5px",
                      borderRadius: "3px"
                    }} onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete!"
                      }).then(async (result) => {
                        if (result.isConfirmed) {
                          await doDeleteUser(user.id)
                        }
                      });
                    }}>
                      <DeleteIcon fontSize={"medium"}/>
                    </Button>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </RainTable>
    </Container>
  )
}
