import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {Box, Grid, Input, Option, Select} from '@mui/joy';
import {useState} from 'react';
import {SimulatorContext} from '../../context/SimulatorContext';

const REFILL_OPTIONS = [0, 1, 2, 3, 4, 5];

export const GenerateRegionModal = ({
  isOpen,
  onClose,
  handleGenerate,
}: {
  isOpen: boolean;
  onClose: () => void;
  handleGenerate: (obj: any) => void;
}) => {
  const [name, setName] = useState('');
  const [refillAttempt, setRefillAttempt] = useState(0);
  const [showError, setShowError] = useState(false);
  const [store, setStore] = SimulatorContext.useStore(store => store);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(0px)',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: 500,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{m: 1}} />
        <Typography
          id="modal-title"
          component="h3"
          level="h3"
          textAlign="center"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Generate Installation
        </Typography>
        <Typography level="body2">
          You will generate installations inside drawn region.
        </Typography>
        <Grid container alignItems={'center'} rowGap={1} my={1}>
          <Grid xs={12}>
            <Typography level="body2" fontWeight={700}>
              Region Name
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Input
              placeholder="Protected Area"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            {showError && (
              <Typography level="body3" color="danger">
                Please provide a name for the region
              </Typography>
            )}
          </Grid>
          <Grid xs={12}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography level="body2" fontWeight={700}>
                Refill Attempts
              </Typography>
              <Typography level="body2">
                Determine how many aircraft can simultaneously refill at the
                nearest station.  
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Select
              defaultValue={0}
              placeholder="Choose one…"
              onChange={(_, v) => setRefillAttempt(v || 0)}
            >
              {REFILL_OPTIONS.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Typography level="body2">
          <b>Strategy</b>
          <br />
          Please choose generation strategy:
          <ol style={{paddingLeft: 16, margin: '8px'}}>
            <li>
              <b>Auto-Detect</b>
              <br />
              Attempt to generate installations maximize coverage for the drawn
              region
            </li>
            <li>
              <b>On-Airports</b>
              <br />
              Add installations on each US Airports inside the drawn region.
              This is useful to simulate coverage by fixed-wing aircraft
            </li>
          </ol>
        </Typography>
        {Object.values(store.stations).length > 0 && (
          <Typography level="body2" color="warning" mt={1}>
            <b>Warning:</b> This generation will remove your current stations
          </Typography>
        )}
        <Box sx={{display: 'flex', mt: 2, gap: 2}}>
          <Button
            onClick={() => {
              if (!name) {
                setShowError(true);
                return;
              }
              handleGenerate({
                name,
                strategy: 'AUTO-DETECT',
                refillAttempt,
              });
              setName('');
              setShowError(false);
              onClose();
            }}
            color="warning"
          >
            Auto-Detect
          </Button>
          <Button
            onClick={() => {
              if (!name) {
                setShowError(true);
                return;
              }
              handleGenerate({
                name,
                strategy: 'ON-AIRPORTS',
                refillAttempt,
              });
              setName('');
              setShowError(false);
              onClose();
            }}
            color="info"
          >
            On-Airports
          </Button>
          <Button
            onClick={() => {
              onClose();
              setName('');
            }}
            color="neutral"
          >
            Cancel
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};
