import {NavigationItemInterface} from "../../data/navigations";
import * as React from "react";
import {useState} from "react";
import {useUserProfile} from "../../hooks/useUserProfile";
import ListItem from "@mui/joy/ListItem";
import ListSubheader from "@mui/joy/ListSubheader";
import IconButton from "@mui/joy/IconButton";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import List from "@mui/joy/List";
import {NavItem} from "./NavItem";

export function NavListItem(props: NavigationItemInterface) {
  const [isOpened, setIsOpened] = useState(true)
  const {data: userProfile} = useUserProfile();

  if (props.allowedRoles && !props.allowedRoles.includes(userProfile?.role || '')) {
    return <></>
  }

  const menus = props.menus.filter(menu => {
    if (!menu.allowedRoles) return true;
    return menu.allowedRoles.includes(userProfile?.role || '');
  })

  if (menus.length === 0) {
    return <></>
  }

  return (
    <ListItem nested>
      <ListSubheader>
        {props.title}
        <IconButton
          size="sm"
          variant="plain"
          color="primary"
          sx={{'--IconButton-size': '24px', ml: 'auto'}}
          onClick={() => setIsOpened(prev => !prev)}
        >
          {isOpened ?
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary"/>
            :
            <KeyboardArrowDownRoundedIcon fontSize="small" color="primary"/>
          }
        </IconButton>
      </ListSubheader>
      <List
        aria-labelledby="nav-list-browse"
        sx={{
          '& .JoyListItemButton-root': {p: '8px'},
          display: isOpened ? "block" : "none"
        }}
      >
        {
          menus.map(menu => {
            return (
              <NavItem
                key={menu.title}
                mode={menu.title}
                icon={menu.icon}
                content={menu.content}
                map={menu.map}
                isBeta={menu.isBeta}
              />
            )
          })
        }
      </List>
    </ListItem>
  )
}
