import * as React from "react";
import {useState} from "react";
import IconButton from "@mui/joy/IconButton";
import {mutate} from "swr";
import toast from "react-hot-toast";
import {useUserProfile} from "../../hooks/useUserProfile";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export default function UserProfileMenu() {
  const [expanded, setExpanded] = useState(false)

  const {data: userProfile} = useUserProfile()

  async function logout() {
    window.localStorage.removeItem("RAIN-ISIM-TOKEN")
    await mutate("apiToken")
    toast.success("Logout success")
  }

  if (!userProfile) {
    return <></>
  }

  return (
    <div style={{position: "relative"}}>
      <IconButton
        id="toggle-mode"
        sx={{
          padding: "0 8px",
          fontSize: "12px",
          borderRadius: "3px",
          display: "flex",
          textAlign: "right",
          gap: "8px"
        }}
        size="sm"
        variant={"outlined"}
        color={"neutral"}
        onClick={() => setExpanded(prev => !prev)}
      >
        <div>
          {userProfile.name}<br/>
          <small style={{display: "inline"}}>{userProfile.role.split("-").join(" ")}</small>
        </div>

        <SupervisorAccountIcon
             style={{borderRadius: "100%", height: "20px", aspectRatio: "1"}}
        />
      </IconButton>
      {expanded &&
        <div style={{position: "absolute", top: "calc(100% + 5px)", right: "0", backgroundColor: "white"}}>
          <IconButton
            id="toggle-mode"
            sx={{
              padding: "0 8px",
              fontSize: "12px",
              borderRadius: "3px",
              display: "flex",
              textAlign: "right",
              gap: "8px"
            }}
            size="sm"
            variant={"outlined"}
            color={"danger"}
            onClick={logout}
          >
            Log Out
            <LogoutRoundedIcon
              style={{borderRadius: "100%", height: "20px", aspectRatio: "1"}}
            />
          </IconButton>
        </div>
      }
    </div>
  );
}
