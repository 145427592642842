export const BASE_URL =
  process.env.REACT_APP_BASE_API ??
  `${window.location.protocol}//${window.location.hostname}:8001`;

export const ApiConfig = {
  GetTile: (tileId: number) => {
    return `${BASE_URL}/tiles-v2/${tileId}`;
  },
  SimulationRun: () => {
    return `${BASE_URL}/api/v1/simulation/run`;
  },
};
