import axios from 'axios';
import {DeckGL} from '@deck.gl/react/typed';
import {FlyToInterpolator, WebMercatorViewport} from '@deck.gl/core/typed';
import {useColorScheme} from '@mui/joy/styles';
import {TileLayer, TripsLayer} from '@deck.gl/geo-layers/typed';
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers/typed';
import {EditableGeoJsonLayer} from '@nebula.gl/layers';
import {DrawPointMode, ViewMode} from '@nebula.gl/edit-modes';
import React, {useEffect, useMemo, useState} from 'react';
import Map, {ScaleControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';
import us_airports from '../data/us-airports.json';
import {INTERACTION_MODE, SimulatorContext, Station} from '../context/SimulatorContext';
import {v4 as uuidv4} from 'uuid';

import H3Tileset2D from '../extensions/H3Tileset';
import {MAPBOX_TOKEN} from '../config/shared';
import SearchLocationBar from './sidepane/SearchLocationBar';
import {ApiConfig} from '../config/api';
import {initialViewport} from "../data/constants";
import {bothObjectHaveSameKeyValue} from "../helpers/comparator";

const enum EDIT_TYPE {
  ADD_FEATURE = 'addFeature',
  ADD_POSITION = 'addPosition',
  REMOVE_POSITION = 'removePosition',
  ADD_TENTATIVE_POSITION = 'addTentativePosition',
  MOVE_POSITION = 'movePosition',
  FINISH_MOVE_POSITION = 'finishMovePosition',
}

// const ANIMATION_FRAME_RATE_FPS = 20;

const styles = {
  mapContainer: {
    height: '100%',
    width: '100%',
  },
};

const lines = (label: string, value: string) => {
  return `
    <div style="display: flex; justify-content: space-between; column-gap: 10px">
        <span>${label}</span>
        <span>${value}</span>
    </div>
`
}

export default function WorldContainer({
  loopLength = 60,
}: // animationSpeed = 1,
{
  loopLength?: number;
}): JSX.Element {
  const {mode} = useColorScheme();

  const [worldMapViewState] = SimulatorContext.useStore(store => store.worldMapViewState)
  const [viewState, setViewState] = useState<{ [key: string]: any }>(worldMapViewState)

  useEffect(() => {
    if (bothObjectHaveSameKeyValue(worldMapViewState, viewState)) return;
    flyToViewState(worldMapViewState)
  }, [worldMapViewState]);

  const [viewport, setViewport] = React.useState(initialViewport);

  const [features, setFeatures] = React.useState({
    type: 'FeatureCollection',
    features: [],
  });

  const [simulatedFires, setSimulatedFires] =
    SimulatorContext.useStore(store => store.simulatedFires)

  const [fireLocation, setFireLocation] = React.useState(
    null as null | { lat: number; lng: number }
  );

  const [protectedRegion, setProtectedRegion] = SimulatorContext.useStore(
    store => store.protectedRegion
  );
  const [interactionMode, setInteractionMode] = SimulatorContext.useStore(
    store => store.interactionMode
  );
  const editMode = React.useMemo(() => {
    if (INTERACTION_MODE.VIEW === interactionMode) {
      return ViewMode;
    } else if (INTERACTION_MODE.ADD_STATION === interactionMode) {
      return DrawPointMode;
    } else if (INTERACTION_MODE.START_FIRE === interactionMode) {
      return DrawPointMode;
    } else if (INTERACTION_MODE.DRAW_REGION === interactionMode) {
      return DrawPointMode;
    }
    return ViewMode;
  }, [interactionMode]);

  const modeConfig = React.useMemo(() => {
    if (!interactionMode) {
      console.warn('InteractionMode does not have a value');
    }
    return {};
  }, [interactionMode]);

  const [selectedFeaturesIndexes, setSelectedFeaturesIndexes] = React.useState(
    [] as any[]
  );

  const [simulationAttrs] = SimulatorContext.useStore(store => store);
  const [activeDetectionSystems] = SimulatorContext.useStore(store => store.activeDetectionSystems);
  const [controlLineAttributes] = SimulatorContext.useStore(store => store.controlLineAttributes);
  const [aircraftOptions] = SimulatorContext.useStore(store => store.aircraftOptions);
  const [selectedAircraftName] = SimulatorContext.useStore(store => store.selectedAircraftName);
  const aircraftAttributes = aircraftOptions[selectedAircraftName];
  const [stations, setStations] = SimulatorContext.useStore(store => store.stations);
  const [loadingCoords, setLoadingCoords] = useState<{
    [id: string]: {
      lat: number,
      lon: number,
      isShown: boolean
    }
  }>({});
  // to show currently loading cells
  const [loadingCellCoords, setLoadingCellCoords] = SimulatorContext.useStore(store => store.loadingCellCoords);

  const isLoading = useMemo(() => {
      return Object.values(loadingCoords).filter(e => e.isShown).length > 0
    }, [loadingCoords]
  );

  const [layerVisibleProperty, _] = SimulatorContext.useStore(store => store.layerVisibleProperty);

  const [selectedStationId, setSelectedStation] = SimulatorContext.useStore(store => store.selectedStationId);
  const [selectedCheckpoint] = SimulatorContext.useStore(store => store.selectedCheckpoint);
  const [ignitionCoverage] = SimulatorContext.useStore(store => store.ignitionCoverage);
  const [simulationFrame] = SimulatorContext.useStore(store => store.simulationFrame);
  const [isMoving, setIsMoving] = React.useState(false);

  const generateGetTileDataFn = () => {
    if (
      isMoving ||
      interactionMode === INTERACTION_MODE.DRAG_STATION ||
      layerVisibleProperty['computed-risk-layer'] === false
    ) {
      return () => Promise.resolve({});
    }
    return async (tile: any) => {
      const {signal} = tile;

      const url = ApiConfig.GetTile(tile.id)

      let response = await axios
        .post(url, {
            installation: Object.values(stations),
            aircraft: {
              cruisingSpeedKmh: aircraftAttributes.cruisingSpeed /* mph to km/h */ * 1.60934,
              timeToTakeoff: aircraftAttributes.timeToTakeoff,
              payloadLiters: aircraftAttributes.cargoPayload /* lbs to Liters */ / 2.2,
              payloadKilograms: aircraftAttributes.cargoPayload /* lbs */ * 0.453592,
            },
            controlLineAttrs: controlLineAttributes,
            activeDetectionSystems: activeDetectionSystems,
          }, {
            signal,
          });
      return response.data.data;
    }
  }

  // @ts-ignore
  H3Tileset2D.afterGetTileIndices = (tiles) => {
    const newData = {}
    for (const tile of tiles) {
      // @ts-ignore
      newData[tile.h3Index] = {
        lat: tile.center[0],
        lon: tile.center[1],
        isShown: true
      }
    }
    setLoadingCoords(newData)
  }

  const initialRiskTileLayer = new TileLayer({
    id: 'computed-risk-layer',
    // @ts-ignore
    TilesetClass: H3Tileset2D,
    getTileData: generateGetTileDataFn(),
    dataComparator: (newData, oldData) => {
      // This seems unnecessary, however, we need it because
      // no data is passed directly to this layer - it's fetched dynamically
      // using the `getTileData` function that's passed in.
      // To update the fetch method, we clone this layer and this function
      // evaluates whether the layer needs to be updated.
      return false;
    },
    minZoom: 3,
    maxZoom: 14,
    getFillColor: (feature: any) => feature.properties.color,
    refinementStrategy: (tiles: any[]) => {
      for (const tile of tiles) {
        if (tile.content) {
          setLoadingCoords(prev => {
            return {
              ...prev,
              [tile.id]: {
                ...prev[tile.id],
                isShown: false
              }
            };
          })
        }
      }
    },
    visible: layerVisibleProperty['computed-risk-layer']
  });

  const [riskTileLayer, setRiskTileLayer] = React.useState(
    initialRiskTileLayer
  );

  const geojson = new GeoJsonLayer({
    id: 'us-airport-layer',
    pickable: true,
    // @ts-ignore
    data: us_airports,
    pointType: 'icon',
    iconAtlas: "https://cdn-icons-png.flaticon.com/512/7720/7720736.png",
    getIcon: () => 'marker',
    onClick: (e) => {
      flyToViewState({
        latitude: e?.coordinate ? e.coordinate[1] : viewState.latitude,
        longitude: e?.coordinate ? e.coordinate[0] : viewState.longitude,
        zoom: 8,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator({speed: 2})
      })
    },
    iconMapping: {
      marker: {
        x: 0,
        y: 0,
        width: 512,
        height: 512,
      }
    },
    iconSizeScale: 2,
    iconSizeMaxPixels: 50,
    getIconSize: 15,
    visible: layerVisibleProperty['us-airport-layer']
  });

  React.useEffect(() => {
    const newRiskTileLayer = riskTileLayer.clone({
      getTileData: generateGetTileDataFn(),
      visible: layerVisibleProperty['computed-risk-layer']
    });

    setRiskTileLayer(newRiskTileLayer);
  }, [
    interactionMode,
    activeDetectionSystems,
    aircraftOptions,
    selectedAircraftName,
    controlLineAttributes,
    stations,
    layerVisibleProperty['computed-risk-layer'],
  ]);

  const editableLayer = new (EditableGeoJsonLayer as any)({
    id: 'rain-station-layer',
    data: features,
    mode: editMode,
    modeConfig,
    selectedFeaturesIndexes,
    visible: layerVisibleProperty['rain-station-layer'],

    // @ts-ignore
    onEdit: ({updatedData, editType, featureIndexes, editContext}) => {
      if (editType !== EDIT_TYPE.ADD_FEATURE) {
        return;
      }

      const newFeatures = editContext.featureIndexes.map(
        (index: number) => updatedData.features[index]
      );

      if (INTERACTION_MODE.ADD_STATION === interactionMode) {
        const newStations: { [key: string]: Station } = newFeatures.reduce(
          (acc: { [key: string]: Station }, ft: any): { [key: string]: Station } => {
            const id = uuidv4();
            return {
              ...acc,
              [id]: {
                id: id,
                location: {
                  lat: ft.geometry.coordinates[1],
                  lng: ft.geometry.coordinates[0],
                },
                aircraftCount: 1,
              },
            };
          },
          {}
        );
        setStations({stations: {...stations, ...newStations}});
      } else if (INTERACTION_MODE.START_FIRE === interactionMode) {
        setSimulatedFires({
          simulatedFires: [...simulatedFires, {
            lat: newFeatures[0].geometry.coordinates[1],
            lng: newFeatures[0].geometry.coordinates[0],
          }]
        });
        // setFireLocation({
        //   lat: newFeatures[0].geometry.coordinates[1],
        //   lng: newFeatures[0].geometry.coordinates[0],
        // });
      } else if (INTERACTION_MODE.DRAW_REGION === interactionMode) {
        setProtectedRegion({
          protectedRegion: [
            ...protectedRegion,
            {
              id: uuidv4(),
              lat: newFeatures[0].geometry.coordinates[1],
              lng: newFeatures[0].geometry.coordinates[0],
            },
          ],
        });
      }
    },
  });

  const ICON_MAPPING = {
    rainStation: {x: 0, y: 0, width: 30, height: 30, mask: false},
  };

  const loadingCellsLayer = new IconLayer({
    id: 'loading-cells-layer',
    data: Object.values(loadingCellCoords).map(coord => {
      return {coordinates: [coord.lng, coord.lat]};
    }),
    getIcon: d => 'marker',
    getColor: [255, 0, 0, 255],
    iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    iconMapping: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.json',
    sizeScale: 5,
    getPosition: d => d.coordinates,
    getSize: d => {
      return 10;
    },
    visible: layerVisibleProperty['icon-layer'],
  });


  const stationLayer = new IconLayer({
    id: 'icon-layer',
    data: Object.values(stations).map(station => {
      return {coordinates: [station.location.lng, station.location.lat], id: station.id};
    }),
    getIcon: d => ({
      // url: 'https://rain-public-assets.s3.us-west-1.amazonaws.com/helicopter.svg',
      url: 'https://rain-public-assets.s3.us-west-1.amazonaws.com/Rain-Station-inverted-2.png',
      width: 30,
      height: 30,
      //width: 1200,
      //height: 1200,
    }),
    sizeScale: 5,
    getPosition: d => d.coordinates,
    getSize: d => {
      return d.id === selectedStationId ? 7 : 5;
    },
    pickable: true,
    visible: layerVisibleProperty['icon-layer'],
    onClick: d => {
      if (INTERACTION_MODE.ADD_STATION === interactionMode) return;
      if (selectedStationId === d.object.id) {
        setSelectedStation({selectedStationId: ''});
        return;
      }
      setSelectedStation({selectedStationId: d.object.id});
    },
    onDragStart: d => {
      setInteractionMode({interactionMode: INTERACTION_MODE.DRAG_STATION});
    },
    onDrag: d => {
      const currentStation = stations[d.object.id];
      setStations({
        stations: {
          ...stations,
          [d.object.id]: {
            ...currentStation,
            location: {
              lat: d.coordinate?.[1] || currentStation.location.lat,
              lng: d.coordinate?.[0] || currentStation.location.lng,
            },
          },
        },
      });
    },
    onDragEnd: d => {
      setInteractionMode({interactionMode: INTERACTION_MODE.VIEW});
    },
  });

  const [rotatingAngle, setRotatingAngle] = useState<number>(0)

  useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(() => {
          setRotatingAngle(prev => prev - 1 % -360)
        }, 10
      );

      return () => clearInterval(intervalId);
    }

    return () => {
    };
  }, [isLoading]);

  const loadingLayer = new IconLayer({
    id: 'loading-layer',
    data: Object
      .values(loadingCoords)
      .filter(loadingCoord => loadingCoord.isShown)
      .map(loadingCoord => {
        return {coordinates: [loadingCoord.lon, loadingCoord.lat]}
      }),
    getIcon: d => ({
      url: 'https://kenangan.s3.ap-southeast-1.amazonaws.com/Spinner-1s-200px(1).gif',
      width: 1000,
      height: 1000,
    }),
    getAngle: () => rotatingAngle,
    getPosition: d => d.coordinates,
    getSize: d => 50,
    visible: layerVisibleProperty['computed-risk-layer']
  });

  const baseStationLayer = new GeoJsonLayer({
    id: 'base-station-layer',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: Object.values(stations).map(station => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [station.location.lng, station.location.lat],
          },
        }
      })
    },
    pointType: 'circle',
    pointRadiusScale: 20,// + 10 * (time / loopLength),
    getRadius: 180,
    filled: true,
    getLineColor: [0, 0, 0, 0],
    // getFillColor: [225, 85, 45, 200],
    lineWidthScale: 5,
    getLineWidth: 200 / viewport.zoom,
  });


  const flightPathLayer = new GeoJsonLayer({
    id: 'flight-path',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: simulatedFires.flatMap(fireLocation => {
        return Object.values(stations)
          .filter(station => {
            const aircraftDist20Mins = aircraftAttributes.cruisingSpeed / 3;

            const aircraftDistanceToFire = turf.distance(
              [station.location.lng, station.location.lat],
              [fireLocation?.lng, fireLocation?.lat],
              {units: 'miles'}
            );

            return aircraftDistanceToFire < aircraftDist20Mins;
          })
          .map(station => {
            return {
              type: 'Feature',
              properties: {},
              geometry: {
                coordinates: [
                  [station.location.lng, station.location.lat],
                  [fireLocation?.lng, fireLocation?.lat],
                ],
                type: 'LineString',
              },
            };
          })
      })
    },
    pickable: true,
    stroked: false,
    filled: true,
    extruded: true,
    lineWidthScale: 10,
    lineWidthMinPixels: 3,
    getLineColor: [0, 0, 0, 200],
    getLineWidth: 1,
    getElevation: 1,
    visible: layerVisibleProperty['flight-path-layer']
  });

  const [time, setTime] = React.useState(0);
  const [animation] = React.useState({id: -1});

  // const animate = () => {
  //   setTime(t => (t + animationSpeed) % loopLength);
  //   // animation.id = setTimeout(animate, 1000 / ANIMATION_FRAME_RATE_FPS);
  //   animation.id = window.requestAnimationFrame(animate);
  // };

  // React.useEffect(() => {
  //   // animation.id = setTimeout(animate, 1000 / ANIMATION_FRAME_RATE_FPS);
  //   // return () => clearTimeout(animation.id);
  //   animation.id = window.requestAnimationFrame(animate);
  //   return () => window.cancelAnimationFrame(animation.id);
  // }, [animation]);

  const pulseFireLayer = new GeoJsonLayer({
    id: 'pulse',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: simulatedFires.map(fireLocation => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [fireLocation.lng, fireLocation.lat],
          },
        }
      })
    },
    pointType: 'circle',
    pointRadiusScale: 10 + 10 * (time / loopLength),
    getRadius:
      40 + (5000 / (viewport.zoom * viewport.zoom)) * 2 * (time / loopLength),
    filled: false,
    lineWidthMinPixels: 2,
    pointRadiusMinPixels: 10,
    getLineColor: [225, 25, 15, 200],
    lineWidthScale: 5,
    getLineWidth: 200 / viewport.zoom,
    visible: layerVisibleProperty['fire-layer']
  });

  const baseFireLayer = new GeoJsonLayer({
    id: 'baseFire',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: simulatedFires.map(fireLocation => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [fireLocation.lng, fireLocation.lat],
          },
        };
      }),
    },
    pointType: 'circle',
    pointRadiusScale: 10 + 10 * (time / loopLength),
    getRadius: viewState.zoom < 14 ? 40 : 0,
    filled: true,
    lineWidthMinPixels: 2,
    pointRadiusMinPixels: 10,
    getLineColor: [0, 0, 0, 0],
    getFillColor: [225, 85, 45, 200],
    lineWidthScale: 5,
    getLineWidth: 200 / viewport.zoom,
    visible: layerVisibleProperty['fire-layer'],
  });

  const simulationFireLayer = new GeoJsonLayer({
    id: 'simulation-fire-layer',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: selectedCheckpoint?.fire ? [selectedCheckpoint?.fire] : [],
    },
    filled: true,
    getFillColor: [225, 85, 45, 200],
    getLineWidth: 0,
    visible: layerVisibleProperty['simulation-layer'] && interactionMode === INTERACTION_MODE.SIMULATION,
  });

  const simulationControlLineLayer = new GeoJsonLayer({
    id: 'simulation-control-line-layer',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: selectedCheckpoint?.controlLines || [],
    },
    filled: true,
    getLineColor: d => d?.properties?.color || [100, 100, 100, 200],
    lineWidthScale: 1,
    visible: layerVisibleProperty['simulation-layer'] && interactionMode === INTERACTION_MODE.SIMULATION,
  });

  const simulationAircraftLayer = new IconLayer({
    id: 'simulation-aircraft-layer',
    data:
      ignitionCoverage?.aircraftTimestamps.map((aircraft: any) => aircraft[simulationFrame]) || [],
    getIcon: d => ({
      url: 'https://kenangan.s3-ap-southeast-1.amazonaws.com/icon/1711004358585-helicopter.png',
      width: 1000,
      height: 1000,
    }),
    getAngle: (d: { angle: number }) => (d?.angle ? 360 - d?.angle : 0),
    getPosition: d => d?.coordinates,
    getSize: d => 40,
    sizeMaxPixels: 40,
    visible: layerVisibleProperty['simulation-layer'] && interactionMode === INTERACTION_MODE.SIMULATION,
  });

  const simulationAircraftTripLayer = new TripsLayer({
    id: 'simulation-aircraft-trip-layer',
    data:
      ignitionCoverage?.aircraftTimestamps.map((aircraftTimestamp: any) => {
        return {
          vendor: 0,
          path: aircraftTimestamp.map((aircraft: any) => aircraft.coordinates),
          timestamps: aircraftTimestamp.map((aircraft: any, index: number) => index),
        };
      }) || [],
    getPath: d => d.path,
    getTimestamps: d => d.timestamps,
    getColor: d => [255, 145, 20, 255],
    opacity: 3,
    widthMinPixels: 8,
    rounded: true,
    trailLength: 150,
    currentTime: simulationFrame,
    visible: layerVisibleProperty['simulation-layer'] && interactionMode === INTERACTION_MODE.SIMULATION,
  });

  const protectedRegionLayer = new GeoJsonLayer({
    id: 'protected-region-layer',
    // @ts-ignore
    data: {
      type: 'FeatureCollection',
      features: [
        ...protectedRegion.map(region => {
          return {
            type: 'Feature',
            properties: {
              id: region.id,
              category: 'protected-region-point',
            },
            geometry: {
              type: 'Point',
              coordinates: [region.lng, region.lat],
            },
          };
        }),
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates:
              protectedRegion.length > 0
                ? [
                  ...protectedRegion.map(region => [region.lng, region.lat]),
                  [protectedRegion[0].lng, protectedRegion[0].lat],
                ]
                : [],
          },
        },
      ],
    },
    stroked: false,
    filled: true,
    pointType: 'circle',
    pickable: true,
    lineWidthScale: 20,
    lineWidthMinPixels: 3,
    getLineColor: [0, 0, 0, 255],
    getFillColor: [0, 0, 0, 255],
    getLineWidth: 1,
    getElevation: 1,
    getRadius: 40,
    pointRadiusScale: 3,
    pointRadiusMinPixels: 3,
    visible: layerVisibleProperty['protected-region-layer'],
    onClick: d => {
      if (INTERACTION_MODE.DRAW_REGION === interactionMode) return;
      setProtectedRegion({
        protectedRegion: protectedRegion.filter(
          region => region.id !== d.object.properties.id
        ),
      });
    },
  });

  function onLayerClick(info: any) {
    if (editMode !== ViewMode) {
      // don't change selection while editing
      return;
    }

    setSelectedFeaturesIndexes(info.object ? [info.index] : []);
  }

  function flyToViewState(newViewState: { [key: string]: any }) {
    const flyToDuration = 500;
    setIsMoving(true)
    setViewState(prev => ({
      ...prev,
      ...newViewState,
      transitionDuration: flyToDuration,
      transitionInterpolator: new FlyToInterpolator({speed: 2}),
    }))
    setTimeout(() => {
      setIsMoving(false);
    }, flyToDuration);
  }

  return (
    <>
      {/* Pulsing Orb for Fire */}
      {/* <div className="loadingio-spinner-ripple-64djws5iray" style={{zIndex: 0}}>
      <div className="ldio-eg9yidr2vvn">
        <div></div>
        <div></div>
      </div>
    </div> */}

      {/* Interaction Mode Dropdown */}
      {/* <div className="dropdown" style={{zIndex: 1}}>
      <button className="dropbtn">Select Mode ({interactionMode})</button>
      <div className="dropdown-content">
        <a href="#" onClick={() => onChangeMode(INTERACTION_MODE.VIEW)}>
          {INTERACTION_MODE.VIEW}
        </a>
        <a href="#" onClick={() => onChangeMode(INTERACTION_MODE.ADD_STATION)}>
          {INTERACTION_MODE.ADD_STATION}
        </a>
        <a href="#" onClick={() => onChangeMode(INTERACTION_MODE.START_FIRE)}>
          {INTERACTION_MODE.START_FIRE}
        </a>
        <a href="#" onClick={() => {
          // @ts-ignore
          setStationFeatures(californiaStateStations);
          // @ts-ignore
          setRiskTileLayer(generateRiskTileLayer(californiaStateStations));
        }}>
          Show US-wide coverage
        </a>
      </div>
    </div> */}

      {/* Fire Response Summary  */}
      {/* { fireLocation &&
      <FireResponseSummary
        aircraft={AircraftUH60A}
        detectionDelayMin={0}
        fireLocation={fireLocation}
        showDetails={() => setShowContainmentDetails(true)}
        stationLocations={stationFeatures.map(st => TURF2LATLNG(st.coordinates))}
    />} */}

      {/* Fire Response Details  */}
      {/* { fireLocation && showContainmentDetails &&
      <FireResponseDetails
        aircraft={AircraftUH60A}
        detectionDelayMin={0}
        fireLocation={fireLocation}
        stationLocations={stationFeatures.map(st => TURF2LATLNG(st.coordinates))}
        onClose={() => setShowContainmentDetails(false)}
    />} */}

      {/* Interactive Map */}
      {/* @ts-ignore */}
      <div style={styles.mapContainer}>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v0.44.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <DeckGL
          initialViewState={viewState}
          layers={[
            riskTileLayer,
            loadingLayer,
            // @ts-ignore
            editableLayer,
            geojson,
            flightPathLayer,
            simulationAircraftTripLayer,
            // baseStationLayer,
            loadingCellsLayer,
            stationLayer,
            pulseFireLayer,
            baseFireLayer,
            protectedRegionLayer,
            simulationFireLayer,
            simulationControlLineLayer,
            simulationAircraftLayer,
          ]}
          controller={{
            dragPan: interactionMode !== INTERACTION_MODE.DRAG_STATION,
          }}
          getCursor={({isHovering, isDragging}) =>
            isDragging ? 'grabbing' : isHovering ? 'pointer' : 'grab'
          }
          onClick={onLayerClick}
          onResize={dimensions => {
            const currentViewport = new WebMercatorViewport(viewState);

            const [lng, lat] = currentViewport.unproject([
              currentViewport.width - dimensions.width / 2,
              currentViewport.height - dimensions.height / 2,
            ]);

            setViewState(v => {
              return {
                ...v,
                width: dimensions.width,
                height: dimensions.height,
                latitude: lat,
                longitude: lng,
              }
            });
          }}
          getTooltip={({object}) => {
            if (object?.properties?.Official_Facility_Name) {
              return {
                html: `<div style="padding: 3px 5px; text-align: left; font-size: 10px">
    <h3 style="margin-bottom: 3px; margin-top: 0; text-align: center">${object.properties.Official_Facility_Name}</h3>
    <hr style="margin-top: 0">
      ${lines('Airport Activation Date', object.properties.Airport_Activation_Date_Mm_Yyyy)}
      ${lines('Associated Aeronautical Chart', object.properties.Associated_Aeronautical_Chart)}
    <hr>
      ${lines('City', object.properties.City_Name)}
      ${lines('Country', object.properties.County_Or_Parish_Name)}
      ${lines('State', object.properties.State_Name + ' ' + object.properties.County_s_State_Post_Office_Code)}
    <hr>
      ${lines('Is A Customs Landing Rights Airport', object.properties.Has_Been_Designated_As_A_Customs_Landing_Rights_Airport)}
      ${lines('Is An International Airport', object.properties.Has_Been_Designated_As_An_International_Airport)}
    <hr>
      ${lines('ICAO Code', object.properties.Icao_Identifier)}
      ${lines('Location Identifier', object.properties.Location_Identifier)}
    <hr>
      ${lines('Magnetic Direction', object.properties.Magnetic_Direction)}
      ${lines('Magnetic Variation', object.properties.Magnetic_Variation)}
</div>`
              }
            }
            if (
              interactionMode !== INTERACTION_MODE.DRAW_REGION &&
              object?.properties?.category === 'protected-region-point'
            ) {
              return {
                html: `<div style="cursor: pointer; padding: 0px 4px">Click to remove protected region</div>`,
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  marginLeft: '16px'
                },
              };
            }
            return null;
          }}
          // @ts-ignore
          onViewStateChange={({viewState}) => setViewState(viewState)}
        >
          <Map
            // @ts-ignore
            // eslint-disable-next-line max-len
            mapboxAccessToken={MAPBOX_TOKEN}
            mapStyle={
              mode === 'light'
                ? 'mapbox://styles/mapbox/light-v10'
                : 'mapbox://styles/mapbox/dark-v10'
            }
            reuseMaps
          >
            <ScaleControl/>
          </Map>
        </DeckGL>
        <SearchLocationBar
          proximity={`${viewState.longitude},${viewState.latitude}`}
          onSearch={({lat, lng}) =>
            flyToViewState({latitude: lat, longitude: lng})
          }
        />
      </div>
    </>
  );
}
