import createStore from './CreateStore';
import {FuelModel} from './EnvironmentTypes';
/**
 * @type OptionType[]
 */
import defaultAircraftOptions from "../data/aircraft-options.json"
import {ReactNode} from "react";
import {initialViewport} from "../data/constants";
import {v4} from "uuid";
import {GeneratedRegionType, LatLng} from '../data/InstallationTypes';

export const enum INTERACTION_MODE {
  VIEW = 'View',
  ADD_STATION = 'Add Rain Station',
  DRAG_STATION = 'Drag Rain Station',
  DRAW_REGION = 'Draw Protection Region',
  START_FIRE = 'Model Fire Response',
  SIMULATION = 'Simulation',
}

export type DetectionSystem = {
  name: string;
  minimumFireSizeSqFt: number;
  detectionLatencySeconds: number;
  category: 'Regional' | 'Utilities';
};

export type AircraftAttributes = {
  name: string;
  cruisingSpeed: number;
  cargoPayload: number;
  timeToTakeoff: number;
  timeToRefill: number;
  endurance: number;
  windTolerance: number;
  acquisitionCost: number;
  annualFixedCost: number;
  hourlyOperationCost: number;
};

export type Station = {
  id: string;
  location: LatLng;
  aircraftCount: number;
};

type FuelControlAttribute = {
  controlLineWidthFt: number;
  coverageConcentrationG100SqFt: number;
};

export type ControlLineAttributes = {
  [key in FuelModel]?: FuelControlAttribute;
};

export type IgnitionCoverageResponseType = {
  id: number;
  minute: number;
  fire: any;
  controlLines: any[];
  station: {
    aircraft: any;
    location: {lat: number; lng: number};
    refillCount: number;
  } | null;
  label?: string;
};

export type IgnitionCoverageType = {
  coverageArea: any[];
  coverageLevel: string;
  fireSizeAtContainmentSqM: number;
  fuelModel: string;
  payloadLitersByMinute: any;
  response: IgnitionCoverageResponseType[];
  firstSite: any;
  aircraftTimestamp: {
    coordinates: number[];
    seconds: number;
    angle: number;
  }[];
  cost: {
    totalAcquisitionCost: number;
    totalAnnualFixedCost: number;
    totalHourlyOperationCost: number;
  };
  totalMinutesSpent: number;
  [key: string]: any;
};

type AppNavigation = {
  content: ReactNode;
  isMapVisible?: boolean;
  mode: string
}

export type OptionType = {
  name: string;
  cruisingSpeed: number;
  cargoPayload: number;
  timeToTakeoff: number;
  timeToRefill: number;
  endurance: number;
  windTolerance: number;
  acquisitionCost: number;
  annualFixedCost: number;
  hourlyOperationCost: number;
}

export type Coordinate = {
  lat: number;
  lng: number;
}

export type SimulationParameters = {
  // Detection
  activeDetectionSystems: DetectionSystem[];

  // Aircraft
  aircraftOptions: {
    [key: string]: OptionType
  },
  selectedAircraftName: string,

  // Containment
  windPercentile: number;
  controlLineAttributes: ControlLineAttributes;

  // Map (Layers & Interaction)
  interactionMode: INTERACTION_MODE;

  // Rain Stations
  stations: {
    [key: string]: Station;
  };

  // Selected Station
  selectedStationId: string;

  // Response Simulation
  ignitionCoverage: IgnitionCoverageType | null;

  // Checkpoint from the response simulation
  selectedCheckpoint: any;

  // Simulation Time
  simulationFrame: number;

  simulatedFires: { lat: number; lng: number; }[]

  // Layer Visibility
  layerVisibleProperty: { [key: string]: boolean }

  // App Navigation
  activeMenu: AppNavigation | null;

  // Protected Region
  protectedRegion: { id: string; lat: number; lng: number; }[]

  // World Map View State. The type is derived from DeckGL 'initialViewState' type definition.
  worldMapViewState: any,

  // Currently loading cells coordinates
  loadingCellCoords: Coordinate[],
  loadingCellCoordsLatestIdx: number,

  // Generated Installations
  generatedInstallations: GeneratedRegionType[];
};

const aircraftOptionsByName: {[key: string]: OptionType} = {}
for (const defaultAircraftOption of defaultAircraftOptions) {
  aircraftOptionsByName[defaultAircraftOption.name] = defaultAircraftOption
}

export const defaultValue: SimulationParameters = {
  activeDetectionSystems: [
    {
      name: 'Smart Grid',
      minimumFireSizeSqFt: 0,
      detectionLatencySeconds: 5,
      category: 'Utilities',
    },
  ],
  aircraftOptions: aircraftOptionsByName,
  selectedAircraftName: defaultAircraftOptions[0].name,
  windPercentile: 0,
  controlLineAttributes: {
    FBFM1: {
      controlLineWidthFt: 5,
      coverageConcentrationG100SqFt: 1,
    },
    FBFM2: {
      controlLineWidthFt: 27,
      coverageConcentrationG100SqFt: 1,
    },
    Agriculture: {
      controlLineWidthFt: 8,
      coverageConcentrationG100SqFt: 2,
    },
    FBFM3: {
      controlLineWidthFt: 8,
      coverageConcentrationG100SqFt: 2,
    },
    FBFM4: {
      controlLineWidthFt: 25,
      coverageConcentrationG100SqFt: 6,
    },
    FBFM5: {
      controlLineWidthFt: 9,
      coverageConcentrationG100SqFt: 3,
    },
    FBFM6: {
      controlLineWidthFt: 18,
      coverageConcentrationG100SqFt: 6,
    },
    FBFM7: {
      controlLineWidthFt: 18,
      coverageConcentrationG100SqFt: 4,
    },
    FBFM8: {
      controlLineWidthFt: 5,
      coverageConcentrationG100SqFt: 4,
    },
    FBFM9: {
      controlLineWidthFt: 9,
      coverageConcentrationG100SqFt: 2,
    },
    FBFM10: {
      controlLineWidthFt: 15,
      coverageConcentrationG100SqFt: 2,
    },
    FBFM11: {
      controlLineWidthFt: 9,
      coverageConcentrationG100SqFt: 3,
    },
    FBFM12: {
      controlLineWidthFt: 24,
      coverageConcentrationG100SqFt: 8,
    },
    FBFM13: {
      controlLineWidthFt: 30,
      coverageConcentrationG100SqFt: 8,
    },
  },

  interactionMode: INTERACTION_MODE.VIEW,

  stations: {},

  selectedStationId: '',

  ignitionCoverage: null,
  selectedCheckpoint: null,
  simulationFrame: 0,

  simulatedFires: [],

  layerVisibleProperty: {
    "computed-risk-layer": true,
    "us-airport-layer": false,
    "rain-station-layer": true,
    "flight-path-layer": true,
    "fire-layer": true,
    "icon-layer": true,
    "simulation-layer": true,
    "protected-region-layer": true,
  },

  activeMenu: null,

  protectedRegion: [],

  worldMapViewState: {
    longitude: initialViewport.longitude,
    latitude: initialViewport.latitude,
    zoom: initialViewport.zoom
  },

  loadingCellCoords: [],
  loadingCellCoordsLatestIdx: 0,

  generatedInstallations: [],
};

export const SimulatorContext = createStore(defaultValue);

// export const { useStore } = SimulatorContext;
