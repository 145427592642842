import IconButton from "@mui/joy/IconButton";
import {INTERACTION_MODE} from "../../context/SimulatorContext";
import * as React from "react";

export function MapInteractionModeButton(
  interactionMode: string,
  setInteractionMode: any,
  currentMode: string,
  icon: JSX.Element
) {
  const isActive = interactionMode === currentMode;
  return (
    <IconButton
      size="sm"
      variant={isActive ? 'solid' : 'outlined'}
      color="neutral"
      onClick={() => {
        isActive
          ? setInteractionMode({interactionMode: INTERACTION_MODE.VIEW})
          : setInteractionMode({interactionMode});
      }}
    >
      {icon}
    </IconButton>
  );
}
