import RadarIcon from "@mui/icons-material/Radar";
import DetectionSidePane from "../components/sidepane/DetectionSidePane";
import EmergencyShareIcon from "@mui/icons-material/EmergencyShare";
import AircraftSidePane from "../components/sidepane/AircraftSidePane";
import PolylineIcon from "@mui/icons-material/Polyline";
import ContainmentSidePane from "../components/sidepane/ContainmentSidePane";
import MapIcon from "@mui/icons-material/Map";
import MapSidePane from "../components/sidepane/MapSidePane";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import SimulatedFiresSidePane from "../components/sidepane/SimulatedFiresSidePane";
import {FlightOutlined, PlayCircleSharp, SupervisedUserCircle} from "@mui/icons-material";
import InstallationSidePane from "../components/sidepane/InstallationSidePane";
import UserManagementSidePane from "../components/sidepane/UserManagementSidePane";
import SimulationSidePane from "../components/sidepane/SimulationSidePane";
import * as React from "react";
import {ReactNode} from "react";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import GeneratedInstallationsSidePane from "../components/sidepane/GeneratedInstallationsSidePane";

export interface NavigationItemInterface {
  title: string,
  menus: {
    title: string,
    icon: JSX.Element,
    content: ReactNode,
    isBeta?: boolean,
    map?: "visible" | "invisible",
    allowedRoles?: string[]
  }[],
  allowedRoles?: string[]
}

const navigations: NavigationItemInterface[] = [
  {
    title: "Configure",
    menus: [
      {
        title: "Detection",
        icon: <RadarIcon fontSize="small"/>,
        content: <DetectionSidePane/>
      },
      {
        title: "Aircraft",
        icon: <EmergencyShareIcon fontSize="small"/>,
        content: <AircraftSidePane/>
      },
      {
        title: "Containment",
        icon: <PolylineIcon fontSize="small"/>,
        isBeta: false,
        content: <ContainmentSidePane/>
      },
      {
        title: "Map",
        icon: <MapIcon fontSize="small"/>,
        isBeta: false,
        content: <MapSidePane/>
      },
      // {
      //   title: "Rain Stations",
      //   icon: <img src={RainStationSVG} alt="logo" style={{width: '1.3em'}} />,
      // },
      {
        title: "Simulated Fires",
        icon: <LocalFireDepartmentIcon/>,
        content: <SimulatedFiresSidePane/>
      },
      {
        title: "Installations",
        icon: <FlightOutlined/>,
        content: <InstallationSidePane/>
      },
      {
        title: "Generated Installations",
        icon: <ShapeLineIcon />,
        content: <GeneratedInstallationsSidePane />,
        allowedRoles: [
          'USER-ADMIN',
          'SUPER-ADMIN'
        ]
      },
      {
        title: "Simulation",
        icon: <PlayCircleSharp/>,
        content: <SimulationSidePane/>,
        allowedRoles: [
          'USER-ADMIN',
          'SUPER-ADMIN'
        ]
      }
    ]
  },
  {
    title: "Administrative",
    menus: [
      {
        title: "User Management",
        icon: <SupervisedUserCircle/>,
        content: <UserManagementSidePane/>,
        map: "invisible"
      },
    ],
    allowedRoles: [
      'USER-ADMIN',
      'SUPER-ADMIN'
    ]
  }
]

export default navigations;
