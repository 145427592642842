import React from 'react';
import {Box, CircularProgress, ListItemContent, Typography} from '@mui/joy';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import {MAPBOX_TOKEN} from '../../config/shared';
import _ from 'lodash';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';

type LocationType = {
  bbox: number[];
  center: number[];
  context: any[];
  geometry: {coordinates: number[]; type: string};
  id: string;
  place_name: string;
  place_type: string[];
  properties: {mapbox_id: string; wikidata: string; short_code: string};
  relevance: number;
  text: string;
  type: string;
  label?: string;
  address?: string;
};

type PropsType = {
  proximity: string;
  onSearch: (props: {lat: number; lng: number}) => void;
};

const GEOCODING_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

const SearchLocationBar = ({proximity, onSearch}: PropsType) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<LocationType | null>(null);
  const [inputValue, setInputvalue] = React.useState<string>('');
  const [locations, setLocations] = React.useState<LocationType[]>([]);

  const handleSearch = async (keyword: string, prox: string) => {
    setLoading(true);
    const url = `${GEOCODING_API}${keyword}.json`;
    await axios
      .get(url, {
        params: {
          limit: 5,
          language: 'en-US',
          access_token: MAPBOX_TOKEN,
          proximity: prox,
        },
      })
      .then(response => {
        const newLocations: LocationType[] = response.data.features.map(
          (location: LocationType) => {
            const [label, ...address] = location.place_name.split(',');
            return {
              ...location,
              label,
              address: address.join(',').trim(),
            };
          }
        );
        setLocations(newLocations);
      })
      .catch(() => {
        setLocations([]);
        console.error('Error fetching search results');
      })
      .finally(() => setLoading(false));
  };

  const debounceFn = React.useCallback(_.debounce(handleSearch, 1000), []);
  function handleChangeInputValue(inputValue: string) {
    setInputvalue(inputValue);
    debounceFn(inputValue, proximity);
  }

  function handleClickSuggest(center: number[]): void {
    onSearch({lat: center[1], lng: center[0]});
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 10,
        width: 300,
        height: 32,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <Autocomplete
        placeholder="Search for a location"
        autoComplete={false}
        value={value}
        onChange={(event, newValue) => {
          if (newValue === null || typeof newValue === 'string') return;
          setValue(newValue);
          handleClickSuggest(newValue.center);
        }}
        filterOptions={(options, state) => options}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          handleChangeInputValue(newInputValue);
        }}
        sx={{width: 300}}
        autoHighlight
        freeSolo
        options={locations}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.id}>
            <ListItemContent sx={{fontSize: 'sm'}}>
              <Typography
                textColor="text.primary"
                level="body3"
                fontWeight={700}
              >
                {option.label}
              </Typography>
              <Typography textColor="text.primary" level="body3">
                {option.address}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
        startDecorator={<SearchIcon />}
        loading={loading}
        endDecorator={
          loading ? <CircularProgress size="sm" color="neutral" /> : null
        }
      />
    </Box>
  );
};

export default SearchLocationBar;
