import {Button, FormControl, FormLabel, Input, Stack, Typography} from '@mui/joy';
import Box from "@mui/joy/Box";
import {FormEvent, useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {mutate} from "swr";
import RainLogo from "./RainLogo.png";
import {PuffLoader, RingLoader} from "react-spinners";
import {SimulatorContext, defaultValue} from "../context/SimulatorContext";

export default function LoginPage() {
  const [store, setStore] = SimulatorContext.useStore(prev => prev)

  const [formData, setFormData] = useState<{
    email: string,
    password: string
  }>({
    email: "",
    password: ""
  })

  function login() {
    axios.post(`${process.env.REACT_APP_BASE_API}/api/v1/user/login`, formData)
      .then(async (result) => {
        const response = result.data as {token: string, message: string};
        window.localStorage.setItem("RAIN-ISIM-TOKEN", response.token)
        toast.success(response.message)

        // reset store to default value
        setStore(defaultValue)
        await mutate("apiToken")
      })
      .catch((err) => {
        let errorMessage = err?.response?.data?.detail?.message;
        if (!errorMessage) {
          errorMessage = "Unknown error occured"
          console.error(err)
        }
        toast.error(errorMessage)
      })
  }

  return (
    <div style={{display: "flex"}}>
      <Box
        sx={(theme) => ({
          width: {xs: '100%', md: '50%'},
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Typography component="h1" level="h3" mb={0}>
              Sign in
            </Typography>

            <Stack gap={4} sx={{mt: 2}}>
              <form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                  event.preventDefault();

                  login()
                }}
              >
                <FormControl required>
                  <FormLabel>Email / Username</FormLabel>
                  <Input onChange={(event) => setFormData(prev => ({
                    ...prev,
                    email: event.target.value
                  }))} defaultValue={formData.email} type="text" name="email"/>
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input onChange={(event) => setFormData(prev => ({
                    ...prev,
                    password: event.target.value
                  }))} defaultValue={formData.password} type="password" name="password"/>
                </FormControl>

                <Stack gap={4} sx={{mt: 2}}>
                  <Button type="submit" fullWidth>
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          width: {xs: '100%', md: '50%'},
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: {xs: 'none', md: 'flex'},
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,

            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <img src={RainLogo} style={{width: "200px"}}/>
          <h1 style={{marginTop: 0}}>
            Installation Simulator
          </h1>
          <RingLoader color={"white"}/>
        </Box>
      </Box>
    </div>
  )
}
