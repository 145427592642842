export enum FuelModel {
  FBFM1 = 'FBFM1',
  FBFM2 = 'FBFM2',
  FBFM3 = 'FBFM3',
  FBFM4 = 'FBFM4',
  FBFM5 = 'FBFM5',
  FBFM6 = 'FBFM6',
  FBFM7 = 'FBFM7',
  FBFM8 = 'FBFM8',
  FBFM9 = 'FBFM9',
  FBFM10 = 'FBFM10',
  FBFM11 = 'FBFM11',
  FBFM12 = 'FBFM12',
  FBFM13 = 'FBFM13',
  Agriculture = 'Agriculture',
  Urban = 'Urban',
  Barren = 'Barren',
  Water = 'Water',
  SnowIce = 'SnowIce',
}

export const FuelModelToName: {[key in FuelModel]?: string} = {
  FBFM1: 'Short Grass',
  FBFM2: 'Grass with Timber/Shub Overstory',
  FBFM3: 'Tall Grass',
  FBFM4: 'Chaparral',
  FBFM5: 'Brush',
  FBFM6: 'Dormant Brush',
  FBFM7: 'Southern Rough',
  FBFM8: 'Closed, Short Needle Timber Litter',
  FBFM9: 'Hardwood or Long Needle Pine Timber Litter',
  FBFM10: 'Mature/Overmature Timber and Understory',
  FBFM11: 'Light Slash',
  FBFM12: 'Medium Slash',
  FBFM13: 'Light Slash',
};

export function isBurnable(fuel: FuelModel) {
  switch (fuel) {
    case FuelModel.Urban:
    case FuelModel.Barren:
    case FuelModel.Water:
    case FuelModel.SnowIce:
      return false;

    default:
      return true;
  }
}

export enum WildfireHazardPotential {
  VeryLow = 'VeryLow',
  Low = 'Low',
  Moderate = 'Moderate',
  High = 'High',
  VeryHigh = 'VeryHigh',
  Nonburnable = 'Nonburnable',
  Water = 'Water',
  Unknown = 'Unknown',
}

// TODO: use a type to require [0, 360)
export type PointEnvironmentConditions = {
  /**
   * miles per hour
   */
  windSpeed: number;
  /**
   * degrees [0, 360)
   */
  windDirection: number;
};

export type PointTopography = {
  slope: number;
  aspect: number;
};
