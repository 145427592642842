import {_Tileset2D as TileSet2D} from '@deck.gl/geo-layers/typed';

import * as h3 from 'h3-js';

// Source: https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames
const tile2long = (x: number, z: number): number => {
  return (x / Math.pow(2, z)) * 360 - 180;
};

const tile2lat = (y: number, z: number) => {
  const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);

  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
};

const H3_TILE_RESOLUTION_STEPS = [-3, -2, -1, 0, 1, 2, 4, 5, 7, 8, 9, 11, 12].map(v => v + 3).reverse();
// [6, 5, 3, 2];
const H3_TILE_RESOLUTION_ZOOM = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].reverse();

const OSMTileZoomToH3Res = (z: number): number => {
  if (z <= 6) {
    return 0
  }
  if (z <= 7) {
    return 1
  }
  if (z <= 8) {
    return 2
  }
  if (z <= 9) {
    return 3
  }
  if (z <= 10) {
    return 4
  }
  if (z <= 11) {
    return 5
  }
  if (z <= 12) {
    return 6
  }
  return 7;
};

/**
 * A function to convert map-definition-of-tile-index to OSM-slippy-map-tile-name
 */
const xyzBoundsToH3Tiles = (xyzBounds: {
  z: number,
  xMax: number,
  xMin: number,
  yMax: number,
  yMin: number
}, viewport_zoom: number) => {

  if (viewport_zoom < 3 || viewport_zoom > 14) {
    return []
  }

  const {z} = xyzBounds;

  /**
   * Get number in max min range, closest to value
   */
  const inRange = (value: number, min: number, max: number): number => {
    if (value > max) {
      return max;
    }
    if (value < min) {
      return min;
    }

    return value;
  };

  /**
   * Normalize {x, y, z} each to the max tiles bound (2^z)
   */
  const boundRange = ({x, y, z}: { x: number, y: number, z: number }) => {
    // Calculate max tiles
    const maxTiles = Math.pow(2, z);

    // Normalize x value
    const newX = inRange(x, 0, maxTiles);

    // Normalize y value
    const newY = inRange(y, 0, maxTiles);

    // Return normalized value
    return {
      x: newX,
      y: newY,
      z
    };
  };

  // Normalize xMin and yMin
  const {x: xMin, y: yMin} = boundRange({
    x: xyzBounds.xMin - 2,
    y: xyzBounds.yMin - 2,
    z
  })

  // Normalize xMax and yMax
  const {x: xMax, y: yMax} = boundRange({
    x: xyzBounds.xMax + 2,
    y: xyzBounds.yMax + 2,
    z
  })

  /**
   * Get the top left lat-long
   */
  const northWestCorner = {
    lat: tile2lat(yMin, z),
    lng: tile2long(xMax, z),
  };

  /**
   * Get the top right lat-long
   */
  const northEastCorner = {
    lat: northWestCorner.lat,
    lng: tile2long(xMin, z),
  };

  /**
   * Get the bottom right lat-long
   */
  const southEastCorner = {
    lat: tile2lat(yMax, z),
    lng: northEastCorner.lng,
  };

  /**
   * Get the bottom left lat-long
   */
  const southWestCorner = {
    lat: southEastCorner.lat,
    lng: northWestCorner.lng,
  };

  const h3TileResolution = OSMTileZoomToH3Res(viewport_zoom);

  const indexes = h3.polyfill(
    [
      northWestCorner,
      northEastCorner,
      southEastCorner,
      southWestCorner,
      northWestCorner,
    ].map(pt => [pt.lat, pt.lng]),
    h3TileResolution
  );

  // return indexes;
  return indexes.map(h3Index => {
    return {
      h3Index: h3Index,
      center: h3.h3ToGeo(h3Index)
    };
  })
}

export default class H3Tileset2D extends TileSet2D {
  // @ts-ignore
  getTileIndices(opts) {
    /**
     * super.getTileIndices will:
     * Returns all tile indices in the current viewport.
     *
     * If the current zoom level is smaller than minZoom, return an empty array.
     * If the current zoom level is greater than maxZoom, return tiles that are on maxZoom.
     *
     * @source TileSet2D
     */
    const intermediate = super.getTileIndices(opts);

    /**
     * Variable intermediate will return array of map-definition-of-tile-index
     * Which differs to OSM slippy map tile names, so we need to convert it to OSM slippy map tile name
     *
     * First, get the max and min map-definition-of-tile-index's X and Y
     * Then, we convert the X and Y of map-definition-of-tile-index to OSM-slippy-map-tile-name
     */
    const start = {
      xMax: Number.NEGATIVE_INFINITY,
      xMin: Number.POSITIVE_INFINITY,
      yMax: Number.NEGATIVE_INFINITY,
      yMin: Number.POSITIVE_INFINITY,
      z: Number.NEGATIVE_INFINITY,
    }

    /**
     *
     * @type {{
     *       xMax: number;
     *       xMin: number;
     *       yMax: number;
     *       yMin: number;
     *       z: number;
     *     }}
     */
    const xyzLimits = intermediate.reduce((accum, iter) => {
      return {
        xMax: Math.max(accum.xMax, iter.x),
        xMin: Math.min(accum.xMin, iter.x),
        yMax: Math.max(accum.yMax, iter.y),
        yMin: Math.min(accum.yMin, iter.y),
        z: Math.max(accum.z, iter.z),
      };
    }, start);

    const result = xyzBoundsToH3Tiles(xyzLimits, opts.viewport.zoom);

    H3Tileset2D.afterGetTileIndices(result)

    return result;
  }

  static afterGetTileIndices(_: { h3Index: string; center: number[] }[]) {
    // This static method is meant to be overridden
  }

  // @ts-ignore
  getTileId(tile: { h3Index?: string } | null) {
    if (!tile || !tile.h3Index) {
      console.error('error with tile - getTileId', tile);
      return
    }
    return tile.h3Index;
  }

  // @ts-ignore
  getTileZoom(tile: { h3Index?: string } | null) {
    if (!tile || !tile.h3Index) {
      console.error('error with tile - getTileZoom', tile);
      return
    }
    const h3Index = tile.h3Index;

    const resolution = h3.h3GetResolution(h3Index);
    const resIndex = H3_TILE_RESOLUTION_STEPS.indexOf(resolution);

    return H3_TILE_RESOLUTION_ZOOM[resIndex];
  }

  // @ts-ignore
  getParentIndex(tile: { h3Index?: string } | null) {
    if (!tile || !tile.h3Index) {
      console.error('error with tile - getParentIndex', tile);
      return;
    }
    const h3Index = tile.h3Index;

    const resolution = h3.h3GetResolution(h3Index);
    const resIndex = H3_TILE_RESOLUTION_STEPS.indexOf(resolution);

    if (resIndex === H3_TILE_RESOLUTION_STEPS.length - 1) {
      return null;
    }

    const parentRes: number = H3_TILE_RESOLUTION_STEPS[resIndex + 1];
    const parentIndex = h3.h3ToParent(h3Index, parentRes);

    return {
      h3Index: parentIndex
    }
  }
}
