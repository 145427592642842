import * as React from 'react';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@mui/joy/Autocomplete';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Slider from '@mui/joy/Slider';

// Icons import
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/UndoRounded';
import {OptionType, SimulatorContext} from '../../context/SimulatorContext';
import {Container, Input, Tooltip} from "@mui/joy";
import _defaultAircraftOptions from "../../data/aircraft-options.json";
import { formatCurrencyUSD } from '../../helper/stringFormatting';

const defaultAircraftOptions: OptionType[] = _defaultAircraftOptions;

type AttrsType = {
  [key: string]: any
}

export default function AircraftSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  const [selectedAircraftName, setSelectedAircraftName] = SimulatorContext.useStore(
    store => store.selectedAircraftName
  );

  const [aircraftOptions, setAircraftOptions] = SimulatorContext.useStore(
    store => store.aircraftOptions
  );

  const [inputName, setInputName] = React.useState(selectedAircraftName);

  const [inputCruisingSpeed, setInputCruisingSpeed] =
    React.useState(aircraftOptions[selectedAircraftName].cruisingSpeed)

  const [inputCargoPayload, setInputCargoPayload] =
    React.useState(aircraftOptions[selectedAircraftName].cargoPayload)

  const [inputTimeToTakeoff, setInputTimeToTakeoff] =
    React.useState(aircraftOptions[selectedAircraftName].timeToTakeoff)

    const [inputTimeToRefill, setInputTimeToRefill] =
    React.useState(aircraftOptions[selectedAircraftName].timeToRefill)

  const [inputEndurance, setInputEndurance] =
    React.useState(aircraftOptions[selectedAircraftName].endurance)

  const [inputAcquisitionCost, setInputAcquisitionCost] =
    React.useState(aircraftOptions[selectedAircraftName].acquisitionCost)

  const [inputAnnualFixedCost, setInputAnnualFixedCost] =
    React.useState(aircraftOptions[selectedAircraftName].annualFixedCost)

  const [inputHourlyOperationCost, setInputHourlyOperationCost] =
    React.useState(aircraftOptions[selectedAircraftName].hourlyOperationCost);

  const [defaultValue, setDefaultValue] =
    React.useState(defaultAircraftOptions.find(e => e.name === selectedAircraftName) as OptionType)

  const [inputWindTolerance, setInputWindTolerance] =
    React.useState(aircraftOptions[selectedAircraftName].windTolerance)

  function handleAircraftAttrInput(attr: AttrsType) {
    setAircraftOptions({
      aircraftOptions: {
        ...aircraftOptions,
        [selectedAircraftName]: {
          ...aircraftOptions[selectedAircraftName],
          ...attr
        },
      },
    });
  }

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })

  function handleAircraftSelection(
    event: React.SyntheticEvent,
    value: OptionType | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionType>
  ) {
    if (reason === 'selectOption' && value) {
      setSelectedAircraftName({
        selectedAircraftName: value.name
      });
      setInputCruisingSpeed(value.cruisingSpeed);
      setInputCargoPayload(value.cargoPayload);
      setInputTimeToTakeoff(value.timeToTakeoff);
      setInputTimeToRefill(value.timeToRefill);
      setInputEndurance(value.endurance);
      setInputWindTolerance(value.windTolerance);
      setInputAcquisitionCost(value.acquisitionCost);
      setInputAnnualFixedCost(value.annualFixedCost);
      setInputHourlyOperationCost(value.hourlyOperationCost);
      setDefaultValue(defaultAircraftOptions.find(e => e.name === value.name) as OptionType)
    }
  }

  const editables = [
    {
      label: "Cruising Speed",
      valueFormat: `${inputCruisingSpeed} mph`,
      value: inputCruisingSpeed,
      setter: setInputCruisingSpeed,
      sliderTooltipFormat: `{value} mph`,
      slider: {
        step: 5,
        min: 50,
        max: 250
      },
      dispatcherTag: "cruisingSpeed",
      defaultValue: defaultValue.cruisingSpeed
    },
    {
      label: "Cargo Payload",
      valueFormat: `${inputCargoPayload} lbs`,
      value: inputCargoPayload,
      setter: setInputCargoPayload,
      sliderTooltipFormat: `{value} lbs`,
      slider: {
        step: 50,
        min: 50,
        max: 20000
      },
      dispatcherTag: "cargoPayload",
      defaultValue: defaultValue.cargoPayload
    },
    {
      label: "Time to Takeoff",
      valueFormat: `${inputTimeToTakeoff} seconds`,
      value: inputTimeToTakeoff,
      setter: setInputTimeToTakeoff,
      sliderTooltipFormat: `{value} seconds`,
      slider: {
        step: 30,
        min: 0,
        max: 600
      },
      dispatcherTag: "timeToTakeoff",
      defaultValue: defaultValue.timeToTakeoff
    },
    {
      label: "Time to Refill",
      valueFormat: `${inputTimeToRefill} seconds`,
      value: inputTimeToRefill,
      setter: setInputTimeToRefill,
      sliderTooltipFormat: `{value} seconds`,
      slider: {
        step: 30,
        min: 0,
        max: 600
      },
      dispatcherTag: "timeToRefill",
      defaultValue: defaultValue.timeToRefill
    },
    {
      label: "Endurance (Flight Time)",
      valueFormat: `${inputEndurance} minutes`,
      value: inputEndurance,
      setter: setInputEndurance,
      sliderTooltipFormat: `{value} minutes`,
      slider: {
        step: 5,
        min: 10,
        max: 180
      },
      dispatcherTag: "endurance",
      defaultValue: defaultValue.endurance
    },
    {
      label: "Wind Tolerance",
      valueFormat: `${inputWindTolerance} mph`,
      value: inputWindTolerance,
      setter: setInputWindTolerance,
      sliderTooltipFormat: `{value} mph`,
      slider: {
        step: 5,
        min: 5,
        max: 100
      },
      dispatcherTag: "windTolerance",
      defaultValue: defaultValue.windTolerance
    },
  ]

  const editablesNumber = [
    {
      label: 'Acquisition Cost',
      valueFormat: `${formatCurrencyUSD(inputAcquisitionCost)} USD`,
      value: inputAcquisitionCost,
      setter: setInputAcquisitionCost,
      sliderTooltipFormat: `{value} USD`,
      startDecorator: '$',
      dispatcherTag: 'acquisitionCost',
      defaultValue: defaultValue.acquisitionCost,
    },
    {
      label: 'Annual Fixed Cost',
      valueFormat: `${formatCurrencyUSD(inputAnnualFixedCost)} USD`,
      value: inputAnnualFixedCost,
      setter: setInputAnnualFixedCost,
      sliderTooltipFormat: `{value} USD`,
      startDecorator: '$',
      dispatcherTag: 'annualFixedCost',
      defaultValue: defaultValue.acquisitionCost,
    },
    {
      label: 'Hourly Operation Cost',
      valueFormat: `${formatCurrencyUSD(inputHourlyOperationCost)} USD`,
      value: inputHourlyOperationCost,
      setter: setInputHourlyOperationCost,
      sliderTooltipFormat: `{value} USD`,
      startDecorator: '$',
      dispatcherTag: 'hourlyOperationCost',
      defaultValue: defaultValue.hourlyOperationCost,
    },
  ];

  let isDefault = editables.map(e => e.value === e.defaultValue).reduce((accumulator, currentValue) => accumulator && currentValue, true);

  return (
    <Container>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 2
      }}>
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Select Default Aircraft
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
        <Button size="sm" variant="plain" sx={{px: 0}}>
          <CloseIcon onClick={onClose}/>
        </Button>
      </Box>

      <Box
        sx={{
          mb: 2,
          display: "flex",
          gap: "5px"
        }}>
        <Autocomplete
          placeholder="Aircraft"
          onChange={handleAircraftSelection}
          options={Object.values(aircraftOptions)}
          inputValue={inputName}
          defaultValue={{
            ...aircraftOptions[selectedAircraftName],
            name: selectedAircraftName,
          }}
          onInputChange={(evt, value) => setInputName(value)}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) =>
            option.name === value.name
          }
        />

        {!isDefault &&
          <Tooltip title={"Reset all parameter to default"} arrow size={"sm"} placement={"top"}>
            <Button size="sm" variant="plain" sx={{px: 0, minHeight: "fit-content"}}>
              <UndoIcon onClick={() => {
                // reset all
                const entries = [...editables, ...editablesNumber]
                const attrs: AttrsType = {};
                for (const editable of entries) {
                  editable.setter(editable.defaultValue)
                  attrs[editable.dispatcherTag] = editable.defaultValue
                }
                handleAircraftAttrInput(attrs);
              }}/>
            </Button>
          </Tooltip>
        }
      </Box>

      {editables.map((editable, idx) => {
        return (
          <Box sx={{mb: 1}} key={editable.label}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <Typography level="body3" textColor="text.primary">
                {editable.label}
              </Typography>
              <div style={{display: "flex", gap: "5px"}}>
                <Typography level="body3" fontWeight={500} textColor="text.primary">
                  {editable.valueFormat}
                </Typography>
                {editable.value !== editable.defaultValue &&
                  <Tooltip title={`Reset to ${editable.sliderTooltipFormat.replace("{value}", editable.defaultValue.toString())}`} arrow size={"sm"} placement={"top"}>
                    <Button size="sm" variant="plain" sx={{p: 0, minHeight: "fit-content"}}>
                      <UndoIcon onClick={() => {
                        editable.setter(editable.defaultValue)
                        handleAircraftAttrInput({
                          [editable.dispatcherTag]: editable.defaultValue,
                        });
                      }}/>
                    </Button>
                  </Tooltip>
                }
              </div>
            </div>
            <Slider
              valueLabelFormat={value => editable.sliderTooltipFormat.replace("{value}", value.toString())}
              value={editable.value}
              step={editable.slider.step}
              sx={{
                pt: 1
              }}
              min={editable.slider.min}
              max={editable.slider.max}
              valueLabelDisplay="auto"
              onChange={(evt, value) => {
                if (Array.isArray(value)) {
                  return;
                }
                editable.setter(value)
              }}
              onChangeCommitted={(evt, value) => {
                if (Array.isArray(value)) {
                  return;
                }
                handleAircraftAttrInput({[editable.dispatcherTag]: value});
              }}
            />
          </Box>
        )
      })}
      {editablesNumber.map((editable, idx) => {
        return (
          <Box sx={{mb: 1}} key={editable.label}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography level="body3" textColor="text.primary">
                {editable.label}
              </Typography>
              <div style={{display: 'flex', gap: '5px'}}>
                <Typography
                  level="body3"
                  fontWeight={500}
                  textColor="text.primary"
                >
                  {editable.valueFormat}
                </Typography>
                {editable.value !== editable.defaultValue && (
                  <Tooltip
                    title={`Reset to ${editable.sliderTooltipFormat.replace(
                      '{value}',
                      editable.defaultValue.toString()
                    )}`}
                    arrow
                    size={'sm'}
                    placement={'top'}
                  >
                    <Button
                      size="sm"
                      variant="plain"
                      sx={{p: 0, minHeight: 'fit-content'}}
                    >
                      <UndoIcon
                        onClick={() => {
                          editable.setter(editable.defaultValue);
                          handleAircraftAttrInput({
                            [editable.dispatcherTag]: editable.defaultValue,
                          });
                        }}
                      />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <Input
              size="sm"
              startDecorator={editable.startDecorator}
              type="number"
              value={editable.value}
              onChange={e => {
                const value = parseInt(e.target.value);
                editable.setter(value);
                handleAircraftAttrInput({[editable.dispatcherTag]: value});
              }}
            />
          </Box>
        );
      })}
    </Container>
  );
}
