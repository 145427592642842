import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import CloseIcon from '@mui/icons-material/Close';
import {SimulatorContext} from '../../context/SimulatorContext';
import {useEffect, useState} from 'react';
import {Container} from '@mui/joy';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  GeneratedRegionType,
  LBS2KG,
  LBS2LITERS,
  MPH2KMH,
} from '../../data/InstallationTypes';
import {GenerateRegionModal} from '../modals/GenerateRegionModal';
import useSWR from 'swr';
import {GenerationCard} from '../card/GenerationCard';

const REFETCH_RATE = 5000;

export default function GeneratedInstallationsSidePane() {
  const token = window.localStorage.getItem('RAIN-ISIM-TOKEN');
  const [store, setStore] = SimulatorContext.useStore(store => store);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [generatedInstallations, setGeneratedInstallations] =
    SimulatorContext.useStore(store => store.generatedInstallations);
  const [refreshInterval, setRefreshInterval] = useState(REFETCH_RATE);
  const [activeGenerationId, setActiveGenerationId] = useState<string>('');

  const {data: generatedInstallationsData, mutate} = useSWR(
    `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/user-region`,
    async url => {
      const response = await axios.get<{generations: GeneratedRegionType[]}>(
        url,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data.generations;
    },
    {
      refreshInterval: refreshInterval,
    }
  );

  useEffect(() => {
    if (generatedInstallationsData) {
      const genProgress = generatedInstallations.filter(
        g => g.status === 'INPROGRESS' || g.status === 'PENDING'
      );

      if (genProgress.length === 0) {
        setRefreshInterval(0);
      }

      setGeneratedInstallations({
        generatedInstallations: generatedInstallationsData,
      });
    }
  }, [generatedInstallationsData]);

  const onClose = () =>
    setStore({
      ...store,
      activeMenu: null,
    });

  async function handleGenerate(data: {
    name: string;
    strategy: GeneratedRegionType['strategy'];
    refillAttempt: number;
  }) {
    // Generate aircraft template to be put on each new installation
    const aircraftAttributes =
      store.aircraftOptions[store.selectedAircraftName];
    const aircraft = {
      name: store.selectedAircraftName,
      cruisingSpeedKmh: MPH2KMH(aircraftAttributes.cruisingSpeed),
      timeToTakeoff: aircraftAttributes.timeToTakeoff,
      payloadLiters: LBS2LITERS(aircraftAttributes.cargoPayload),
      payloadKilograms: LBS2KG(aircraftAttributes.cargoPayload),
      cruisingSpeed: aircraftAttributes.cruisingSpeed,
      cargoPayload: aircraftAttributes.cargoPayload,
      endurance: aircraftAttributes.endurance,
      windTolerance: aircraftAttributes.windTolerance,
      timeToRefill: aircraftAttributes.timeToRefill,
    };

    const body = {
      name: data.name,
      aircraft,
      strategy: data.strategy,
      refillAttempt: data.refillAttempt,
      region: store.protectedRegion,
      controlLineAttributes: store.controlLineAttributes,
      detectionAttributes: store.activeDetectionSystems[0],
    };

    // Remove current installations
    setStore({stations: {}});

    await axios.post(
      `${process.env.REACT_APP_BASE_API}/api/v1/generate-installation/region`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    mutate();
  }

  return (
    <Container
      sx={{
        pl: '8px !important',
        maxHeight: '85vh',
        overflowY: 'auto',
      }}
    >
      <GenerateRegionModal
        isOpen={showGenerateModal}
        onClose={() => setShowGenerateModal(false)}
        handleGenerate={handleGenerate}
      />
      <Box
        sx={{
          pt: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Review Generation
        </Typography>
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>

      <Box
        sx={{pb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
      >
        <Button
          size="sm"
          onClick={() => setShowGenerateModal(true)}
          component="label"
          disabled={store.protectedRegion.length < 3}
        >
          Generate
        </Button>
        {store.protectedRegion.length > 0 && (
          <Button
            size="sm"
            variant="outlined"
            color="danger"
            sx={{fontSize: 'xs', marginLeft: 'auto'}}
            onClick={() => {
              Swal.fire({
                title: 'Clear Region',
                text: 'Are you sure you want to clear the region?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dd3333',
                confirmButtonText: 'Confirm',
              }).then(result => {
                if (result.isConfirmed) {
                  setStore({protectedRegion: []});
                }
              });
            }}
          >
            Clear Region
          </Button>
        )}
      </Box>
      {generatedInstallations.length > 0 &&
        generatedInstallations.map(generation => (
          <GenerationCard
            key={`${generation.id}-${Date.now()}`}
            generation={generation}
            mutate={mutate}
            isActive={activeGenerationId === generation.id}
            setActiveGeneration={setActiveGenerationId}
          />
        ))}
    </Container>
  );
}
