import * as React from 'react';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@mui/joy/Autocomplete';
import Box from '@mui/joy/Box';
// import Chip from '@mui/joy/Chip';
// import ChipDelete from '@mui/joy/ChipDelete';
import FormControl from '@mui/joy/FormControl';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Slider from '@mui/joy/Slider';

// Icons import
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CloseIcon from '@mui/icons-material/Close';
import {SimulatorContext} from '../../context/SimulatorContext';

type OptionType = {
  category: 'Regional' | 'Utilities';
  name: string;
  minimumFireSizeSqFt: number;
  detectionLatencySeconds: number;
};

const DetectionOptions: OptionType[] = [
  {
    category: 'Utilities',
    name: 'Smart Grid',
    minimumFireSizeSqFt: 0,
    detectionLatencySeconds: 5,
  },
  {
    category: 'Utilities',
    name: 'Pole-mounted infrared sensors',
    minimumFireSizeSqFt: 10,
    detectionLatencySeconds: 30,
  },
  {
    category: 'Regional',
    name: 'Lightning detection',
    minimumFireSizeSqFt: 0,
    detectionLatencySeconds: 5,
  },
  {
    category: 'Regional',
    name: 'ALERT Wildfire camera network',
    minimumFireSizeSqFt: 21780,
    detectionLatencySeconds: 5,
  },
  {
    category: 'Regional',
    name: 'GOES weather satellites',
    minimumFireSizeSqFt: 5445,
    detectionLatencySeconds: 150,
  },
  {
    category: 'Regional',
    name: 'Persistent surveillance UAS',
    minimumFireSizeSqFt: 100,
    detectionLatencySeconds: 5,
  },
];

export default function DetectionSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  const [activeDetectionSystems, setActiveDetectionSystems] =
    SimulatorContext.useStore(store => store.activeDetectionSystems);

  const [inputMinimumFireSizeSqFt, setInputMinimumFireSizeSqFt] =
    React.useState(activeDetectionSystems[0].minimumFireSizeSqFt);

  const [inputLatency, setInputLatency] = React.useState(
    activeDetectionSystems[0].detectionLatencySeconds
  );

  const [inputValue, setInputValue] = React.useState(
    activeDetectionSystems[0].name
  );

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })

  function handleDetectionSelection(
    event: React.SyntheticEvent,
    value: OptionType | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OptionType>
  ) {
    if (reason === 'selectOption' && value) {
      setActiveDetectionSystems({
        activeDetectionSystems: [
          {
            name: value.name,
            minimumFireSizeSqFt: value.minimumFireSizeSqFt,
            detectionLatencySeconds: value.detectionLatencySeconds,
            category: value.category,
          },
        ],
      });
      setInputMinimumFireSizeSqFt(value.minimumFireSizeSqFt);
      setInputLatency(value.detectionLatencySeconds);
    }
  }

  function updateDetectionAttribute(key: string, value: unknown) {
    setActiveDetectionSystems({
      activeDetectionSystems: [
        {
          ...activeDetectionSystems[0],
          [key]: value,
        },
      ],
    });
  }

  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Configure wildfire detection
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose} />
        </Button>
      </Box>
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body2" textColor="text.primary">
            Select active systems
          </Typography>
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{'--IconButton-size': '24px'}}
          >
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
        <Box sx={{mt: 2}}>
          <FormControl>
            <Autocomplete
              placeholder="Regional, utilities, etc"
              options={DetectionOptions}
              groupBy={option => option.category}
              getOptionLabel={option => option.name}
              inputValue={inputValue}
              onInputChange={(evt, value) => setInputValue(value)}
              value={activeDetectionSystems[0]}
              onChange={handleDetectionSelection}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
            />
          </FormControl>
          {/* <Box sx={{mt: 2, display: 'flex', gap: 1}}>
            <Chip
              variant="soft"
              size="sm"
              endDecorator={<ChipDelete variant="soft" />}
              sx={{'--Chip-radius': theme => theme.vars.radius.sm}}
            >
              ALERTWildfire
            </Chip>
          </Box> */}
        </Box>
      </Box>
      <Divider />
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body2" textColor="text.primary">
            {Array.isArray(activeDetectionSystems) &&
            activeDetectionSystems.length > 0
              ? activeDetectionSystems[0].name
              : 'Custom'}
          </Typography>
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{'--IconButton-size': '24px'}}
          >
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
        <Box sx={{mt: 2}}>
          <Typography level="body3" textColor="text.primary">
            Smallest detectable fire
          </Typography>
          <Box sx={{mt: 3, display: 'flex', gap: 1}}>
            <Slider
              valueLabelFormat={value => `${value} sqft`}
              value={inputMinimumFireSizeSqFt}
              onChange={(evt, value) => {
                if (Array.isArray(value)) {
                  console.log('value is an array');
                  return;
                }

                setInputMinimumFireSizeSqFt(value);
              }}
              onChangeCommitted={(evt, value) => {
                if (Array.isArray(value)) {
                  console.log('value is an array');
                  return;
                }
                updateDetectionAttribute('minimumFireSizeSqFt', value);
              }}
              step={20}
              min={0}
              max={10000}
              valueLabelDisplay="on"
            />
          </Box>
          <Typography level="body3" textColor="text.primary">
            Latency to observe/communicate/confirm detection
          </Typography>
          <Box sx={{mt: 3, display: 'flex', gap: 1}}>
            <Slider
              valueLabelFormat={value => `${value} seconds`}
              value={inputLatency}
              onChange={(evt, value) => {
                if (Array.isArray(value)) {
                  console.log('value is an array');
                  return;
                }

                setInputLatency(value);
              }}
              onChangeCommitted={(evt, value) => {
                if (Array.isArray(value)) {
                  console.log('value is an array');
                  return;
                }
                updateDetectionAttribute('detectionLatencySeconds', value);
              }}
              step={10}
              min={0}
              max={300}
              valueLabelDisplay="on"
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      {/* <Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Education
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowUpRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
              <RadioGroup name="education" defaultValue="any">
                  <Radio label="Any" value="any" size="sm" />
                  <Radio label="High School" value="high-school" size="sm" />
                  <Radio label="College" value="college" size="sm" />
                  <Radio label="Post-graduate" value="post-graduate" size="sm" />
              </RadioGroup>
          </Box>
      </Box><Divider /><Box sx={{ p: 2 }}>
          <Box
              sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
              }}
          >
              <Typography level="body2" textColor="text.primary">
                  Previous experience
              </Typography>
              <IconButton
                  size="sm"
                  variant="plain"
                  color="primary"
                  sx={{ '--IconButton-size': '24px' }}
              >
                  <KeyboardArrowDownRoundedIcon fontSize="small" color="primary" />
              </IconButton>
          </Box>
      </Box> */}
    </>
  );
}
