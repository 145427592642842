import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';

// Icons import
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/joy/Checkbox';
import {SimulatorContext} from "../../context/SimulatorContext";

export default function MapSidePane() {
  const [store, setStore] = SimulatorContext.useStore(store => store)

  const [layerVisibleProperty, setLayerVisibleProperty] = SimulatorContext.useStore(store => store.layerVisibleProperty);

  const onClose = () => setStore({
    ...store,
    activeMenu: null
  })
  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          fontSize="xs2"
          textColor="text.tertiary"
          textTransform="uppercase"
          letterSpacing="md"
          fontWeight="lg"
        >
          Configure Map
        </Typography>
        {/* <Button size="sm" variant="plain" sx={{fontSize: 'xs', px: 1}}>
          Restore defaults
        </Button> */}
        <Button size="sm" variant="plain">
          <CloseIcon onClick={onClose}/>
        </Button>
      </Box>
      <Box sx={{p: 2}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="body2" textColor="text.primary">
            Active Map Layers
          </Typography>
          <IconButton
            size="sm"
            variant="plain"
            color="primary"
            sx={{'--IconButton-size': '24px'}}
          >
            <KeyboardArrowUpRoundedIcon fontSize="small" color="primary"/>
          </IconButton>
        </Box>
        <Box sx={{mt: 2}}>
          <Box role="group" aria-labelledby="sandwich-group">
            <List size="sm">
              <ListItem>
                <Checkbox label="Wildfire Risk"
                          defaultChecked={layerVisibleProperty["computed-risk-layer"]}
                          onChange={(e) => {
                            setLayerVisibleProperty({
                              layerVisibleProperty: {
                                ...layerVisibleProperty,
                                "computed-risk-layer": e.target.checked
                              }
                            })
                          }}/>
              </ListItem>
              <ListItem>
                <Checkbox label="US Airports"
                          defaultChecked={layerVisibleProperty["us-airport-layer"]}
                          onChange={(e) => {
                            setLayerVisibleProperty({
                              layerVisibleProperty: {
                                ...layerVisibleProperty,
                                "us-airport-layer": e.target.checked
                              }
                            })
                          }}/>
              </ListItem>
              <ListItem>
                <Checkbox
                  label="Rain Station"
                  defaultChecked={
                    layerVisibleProperty['icon-layer'] &&
                    layerVisibleProperty['rain-station-layer']
                  }
                  onChange={e => {
                    setLayerVisibleProperty({
                      layerVisibleProperty: {
                        ...layerVisibleProperty,
                        'icon-layer': e.target.checked,
                        'rain-station-layer': e.target.checked,
                      },
                    });
                  }}
                />
              </ListItem>
              <ListItem>
                <Checkbox label="Flight Path"
                          defaultChecked={layerVisibleProperty["flight-path-layer"]}
                          onChange={(e) => {
                            setLayerVisibleProperty({
                              layerVisibleProperty: {
                                ...layerVisibleProperty,
                                "flight-path-layer": e.target.checked
                              }
                            })
                          }}/>
              </ListItem>
              <ListItem>
                <Checkbox label="Fire"
                          defaultChecked={layerVisibleProperty["fire-layer"]}
                          onChange={(e) => {
                            setLayerVisibleProperty({
                              layerVisibleProperty: {
                                ...layerVisibleProperty,
                                "fire-layer": e.target.checked
                              }
                            })
                          }}/>
              </ListItem>
              <ListItem>
                <Checkbox
                  label="Simulation"
                  defaultChecked={layerVisibleProperty['simulation-layer']}
                  onChange={e => {
                    setLayerVisibleProperty({
                      layerVisibleProperty: {
                        ...layerVisibleProperty,
                        'simulation-layer': e.target.checked,
                      },
                    });
                  }}
                />
              </ListItem>
              <ListItem>
                <Checkbox
                  label="Protected Region"
                  defaultChecked={layerVisibleProperty['protected-region-layer']}
                  onChange={e => {
                    setLayerVisibleProperty({
                      layerVisibleProperty: {
                        ...layerVisibleProperty,
                        'protected-region-layer': e.target.checked,
                      },
                    });
                  }}
                />
              </ListItem>
              <ListItem>
                <Checkbox disabled label="USGS Fuel Model"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="USFS Wildfire Suppression Difficulty Index"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="Historic Wildfires"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="Fire Agency Jurisdiction"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="CPUC Transmission Lines"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="FAA Airspace Sectional Chart"/>
              </ListItem>
              <ListItem>
                <Checkbox disabled label="Land Ownership"/>
              </ListItem>
            </List>
          </Box>

          {/* <CheckB
              <RadioGroup name="education" defaultValue="any">
                  <Radio label="Any" value="any" size="sm" />
                  <Radio label="High School" value="high-school" size="sm" />
                  <Radio label="College" value="college" size="sm" />
                  <Radio label="Post-graduate" value="post-graduate" size="sm" />
              </RadioGroup> */}
        </Box>
      </Box>
    </>
  );
}
