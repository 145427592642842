import { AircraftAttributes, ControlLineAttributes, DetectionSystem } from "../context/SimulatorContext";

export type LatLng = {
  lat: number;
  lng: number;
};

export const KG2LBS = (kg: number) => kg / 0.453592;
export const LBS2KG = (lbs: number) => lbs * 0.453592;

export const LITERS2GALLONS = (liters: number) => liters / 3.78541;
export const GALLONS2LITERS = (gallons: number) => gallons * 3.78541;

export const KMH2MPH = (kmh: number) => kmh / 1.60934;
export const MPH2KMH = (mph: number) => mph * 1.60934;

export const LBS2LITERS = (lbs: number) => lbs / 2.2;
export const LITERS2LBS = (liters: number) => liters * 2.2;

export type Aircraft = {
  payloadKilograms: number;
  payloadLiters: number;
  timeToRefill: number;
  timeToTakeoff: number;
  cruisingSpeedKmh: number;
  acquisitionCost?: number;
  annualFixedCost?: number;
  hourlyOperationCost?: number;
};

export type RainStation = {
  id?: string;
  location: LatLng;
  aircraft: Aircraft;
  aircraftCount: number;
  refillCount?: number;
};

export type RainInstallation = Array<RainStation>;

export enum CoverageLevel {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  OBSERVED = 'OBSERVED',
  SLOWED = 'SLOWED', // 60% of perimeter
  CONTAINED = 'CONTAINED', // 100% contained
}

export type PayloadDelivery = {
  actual: number;
  required: number;
};

export type Coverage = {
  coverageArea: Array<LatLng>;

  fireSizeAtContainmentSqM: number;
  // note: map should be sorted by time
  payloadLitersByMinute: Map<number, PayloadDelivery>;
  coverageLevel: CoverageLevel;
};

export type CellCoverage = {
  h3Index: string;
  fireSizeAtContainmentSqM: number;
  coverageLevel: CoverageLevel;
  payloadLitersByMinute: Map<number, PayloadDelivery>;
};

export type RegionCoverage = {
  [key in CoverageLevel]: CellCoverage[];
};


export type AircraftType = {
  aircraftName: string;
  aircraftAttributes: AircraftAttributes;
};

export type GeneratedRegionType = {
  id: string;
  name: any;
  status: 'PENDING' | 'INPROGRESS' | 'DONE' | 'PAUSED';
  strategy: 'AUTO-DETECT' | 'ON-AIRPORTS';
  input: {
    refillAttempt: number;
    aircraft: {
      cruisingSpeedKmh: number;
      timeToTakeoff: number;
      timeToRefill: number;
      payloadLiters: number;
      payloadKilograms: number;
      name?: string;
      cruisingSpeed?: number;
      cargoPayload?: number;
      endurance?: number;
      windTolerance?: number;
    };
    region: {id: string; lat: number; lng: number}[];
    controlLineAttributes: ControlLineAttributes;
    detectionAttributes: DetectionSystem;
  };
  result:
    | {
        installations: RainInstallation;
        installationsCount: number;
        processed: number;
        total: number;
        percentage: number;
        startedAt: Date | null;
        updatedAt: Date | null;
        finishedAt: Date | null;
      }
    | undefined;
};
