import useSWR, {mutate} from "swr";
import axios, {AxiosError} from "axios";
import toast from "react-hot-toast";

interface User {
  name: string,
  email: string,
  id: string,
  username: string,
  role: string
}

export default function useUserList() {
  return useSWR<null | User[]>("list-users", async () => {
    const token = window.localStorage.getItem("RAIN-ISIM-TOKEN")
    if (!token) return null;
    return await axios.get(`${process.env.REACT_APP_BASE_API}/api/v1/user/list`, {
      headers: {
        "Authorization": "Bearer " + token
      }
    }).then((response) => {
      return response.data as User[]
    }).catch((reason: AxiosError) => {
      const message = (reason.response?.data as any)?.detail?.message;
      if (message) {
        toast.error(message)
      }

      if (reason.response?.status === 401) {
        window.localStorage.removeItem("RAIN-ISIM-TOKEN")
        mutate("apiToken")
        toast.success("Session expired. Please login.")
      }

      return null;
    })
  })


}
