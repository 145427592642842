export function bothObjectHaveSameKeyValue(obj1: { [key: string]: any }, obj2: { [key: string]: any }) {
  // Get all keys from both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if number of keys are same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if values for each key are equal
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // All key-value pairs are equal
  return true;
}
