import {useEffect, useState} from 'react';
import _ from 'lodash';
import {Box, Grid, IconButton, Slider, Card, sliderClasses} from '@mui/joy';
import {PauseCircleFilled, PlayCircleFilled} from '@mui/icons-material';
import {INTERACTION_MODE, SimulatorContext} from '../context/SimulatorContext';
import {Typography} from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';

const FRAME_PER_SECONDS = 24;
const INTERVAL_TIME = 1000 / FRAME_PER_SECONDS;

const SimulatorPlayback = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [store, setStore] = SimulatorContext.useStore(store => store);
  const [ignitionCoverage] = SimulatorContext.useStore(
    store => store.ignitionCoverage
  );
  const [simulationFrame, setSimulationFrame] = SimulatorContext.useStore(
    store => store.simulationFrame
  );
  const [selectedCheckpoint, setSelectedCheckpoint] = SimulatorContext.useStore(
    store => store.selectedCheckpoint
  );
  const totalMinutesSpent = ignitionCoverage?.totalMinutesSpent || 0;
  const TOTAL_FRAMES = Math.ceil(totalMinutesSpent * 60);

  const [frame, setFrame] = useState(0);
  const [timerInterval, setTimerInterval] = useState<any>(null);

  const currentTime = moment.utc(frame * 1000).format('HH:mm:ss');
  const totalTime = moment.utc(TOTAL_FRAMES * 1000).format('HH:mm:ss');

  const timeToTakeoff = ignitionCoverage
    ? ignitionCoverage?.response?.[0]?.station?.aircraft?.timeToTakeoff
    : null;

  const isPlayDisabled =
    !ignitionCoverage || ignitionCoverage?.response?.length === 0;

  const ignitionMarks =
    ignitionCoverage?.response?.map((data, index) => {
      return {
        value: (data?.minute || 0) * 60,
        label:
          index === 0
            ? 'Start'
            : index === ignitionCoverage?.response?.length - 1
            ? 'End'
            : '',
      };
    }) || [];

  const handleValueLabel = (value: number) => {
    if (value === 0) return 'Start';
    if (value < timeToTakeoff) return 'Takeoff';
    if (value >= TOTAL_FRAMES) return 'End';
    if (!ignitionCoverage) return '';
    for (let i = 0; i < ignitionCoverage.response.length; i++) {
      if (value < ignitionCoverage.response[i].minute * 60) {
        return ignitionCoverage.response[i].label || '';
      }
    }
    return '';
  };

  const startTimer = () => {
    if (frame >= TOTAL_FRAMES) {
      setFrame(0);
    }
    const interval = setInterval(() => {
      setFrame(t => t + 1);
    }, INTERVAL_TIME);
    setTimerInterval(interval);
    setIsPlaying(true);
  };

  const pauseTimer = () => {
    clearInterval(timerInterval);
    setIsPlaying(false);
  };

  const stopTimer = () => {
    pauseTimer();
    setFrame(0);
  };

  useEffect(() => {
    setSimulationFrame({simulationFrame: frame});
    ignitionCoverage?.response?.forEach((resp, index) => {
      const targetFrame = Math.ceil(resp.minute * 60);
      if (frame >= targetFrame) {
        setSelectedCheckpoint({
          selectedCheckpoint: resp,
        });
      }
    });
    if (frame >= TOTAL_FRAMES) {
      pauseTimer();
    }
  }, [frame]);

  useEffect(() => {
    if (ignitionCoverage) {
      stopTimer();
    }
  }, [ignitionCoverage]);

  useEffect(() => {
    if (selectedCheckpoint) {
      setFrame(Math.ceil(selectedCheckpoint?.minute * 60));
    }
  }, [selectedCheckpoint]);

  const onClose = () => {
    setStore({
      interactionMode: INTERACTION_MODE.VIEW,
    });
  }

  return (
    <Card
      sx={{
        '--Card-radius': '0px',
      }}
    >
      <Box
        display={'flex'}
        gap={1}
        alignItems="center"
        flexDirection={{xs: 'column-reverse', sm: 'row'}}
        flexWrap={{xs: 'wrap', sm: 'nowrap'}}
        sx={{
          width: {
            xs: '100%',
            sm: 'auto',
          },
        }}
      >
        <Box
          display={'flex'}
          gap={1}
          alignItems="center"
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          {!isPlaying ? (
            <IconButton
              variant="solid"
              onClick={startTimer}
              disabled={isPlayDisabled}
            >
              <PlayCircleFilled />
            </IconButton>
          ) : (
            <IconButton
              variant="solid"
              onClick={pauseTimer}
              disabled={isPlayDisabled}
            >
              <PauseCircleFilled />
            </IconButton>
          )}
          <Box minWidth={160}>
            <Typography variant="body2" textAlign={'center'}>
              {currentTime}&nbsp;/&nbsp;{totalTime}
            </Typography>
          </Box>
        </Box>
        <Box width={'100%'}>
          <Slider
            disabled={!ignitionCoverage}
            value={frame}
            step={1}
            min={0}
            valueLabelDisplay={'auto'}
            valueLabelFormat={value => handleValueLabel(value)}
            marks={ignitionMarks.length > 0 ? ignitionMarks : false}
            max={TOTAL_FRAMES}
            onChange={(evt, value) => {
              if (Array.isArray(value)) {
                return;
              }
              setFrame(value);
            }}
            sx={{
              width: '100%',
              [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
                [`& .${sliderClasses.valueLabel}`]: {
                  left: 0,
                  borderBottomLeftRadius: 0,
                  '&::before': {
                    left: 0,
                    transform: 'translateY(100%)',
                    borderLeftColor: 'currentColor',
                  },
                },
              },
              [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
                [`& .${sliderClasses.valueLabel}`]: {
                  right: 0,
                  borderBottomRightRadius: 0,
                  '&::before': {
                    left: 'initial',
                    right: 0,
                    transform: 'translateY(100%)',
                    borderRightColor: 'currentColor',
                  },
                },
              },
            }}
          />
        </Box>
        <Box
          ml={3}
          sx={{
            cursor: 'pointer',
            alignSelf: 'flex-start',
            padding: '2px 4px',
            borderRadius: '4px',
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        >
          <CloseIcon onClick={onClose} fontSize="small" color="disabled" />
        </Box>
      </Box>
    </Card>
  );
};

export default SimulatorPlayback;
